import React from "react";
import "../style/loading.css";

const TableLoading = () => {
  return (
    <div className="loading">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );
};

export default TableLoading;