import logo from '../assets/logo.png';
// import mobile from '../assets/mobile.jpg';
import mobile from '../assets/mobile2.jpeg';
import apple from '../assets/apple.jpg';
import google from '../assets/google.jpg';
import { Link } from 'react-router-dom';
import {subscribeUser} from '../utils/api';
import {isEmail} from '../utils/helper'
import { useState } from 'react';
import toast from "react-hot-toast";


function Footer() {
    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [subscribeEnable, setSubscribeEnable] = useState(false);
    function onChangeHandler(e){
      setSubscribeEmail(e.target.value)
      if(isEmail(subscribeEmail)){
        setSubscribeEnable(true)
      }else{
        setSubscribeEnable(false)
      }
    }
    async function subscribeHandler(){
      try{
        const payload = {
          'email': subscribeEmail,
          "email_group": "Newsletter"
        }
        console.log(payload)
        const res = await subscribeUser(payload);
        console.log(res)
        toast.success(res, {position: 'top-center'})
        setSubscribeEmail('');
        setSubscribeEnable(false)
      }catch(err){
        console.log(err)
      }
    }

    return (
      <footer>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="ft-menu ft-menu-2">
                    <img alt='' src={logo} className="img-fluid" width="200px" />
                    <h3 className='mb-0 pb-0'>Corporate Office:</h3>
                    <p className='pb-0 pt-0'>6B, Vandana Building, Tolstoy Road,
                        Connaught Place, New Delhi - 110001
                        India
                        <a href="mailto:hello@aksum.co.in">Email - hello@aksum.co.in</a>
                        <a href="tel:918826933166">Phone - 91-8826933166</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="ft-menu d-flex">
                    <div>
                      <h3>About Us</h3>
                      <a href="/about-us"> What we do</a>
                      <a href="/about-us">Why you should associate</a>
                      <a href="/our-team">Our Team</a>
                      <a href="/careers">Careers</a>
                      <Link to="/contact-us">Contact Us</Link>
                    </div>
                    {/* <div>
                      <h3>Our Services</h3>
                      <a href="/supply-chain">Supply Chain</a>
                      <a href="/logistic">Logistic</a>
                      <a href="/financing">Financing</a>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="ft-menu d-flex">
                    <div>
                      <h3>Resources</h3>
                      <Link target='_blank' to="https://aksumtrademart.blogspot.com/"> Our Blogs</Link>
                      <Link to={"/faq"}>FAQ's</Link>
                    </div>
                    <div>
                      <h3>Social</h3>
                      <Link target='_blank' to="https://www.facebook.com/aksumtrademart">Facebook</Link>
                      <Link target='_blank' to="https://twitter.com/aksumtrademart">Twitter</Link>
                      <Link target='_blank' to="https://in.linkedin.com/company/aksum-trademart-private-limited">Linkedin</Link>
                    </div>
                  </div>
                  <div className="news w-100">
                    <div className="input-group mb-3">
                      <input onChange={(e) => onChangeHandler(e)} type="text" className="form-control" placeholder="Enter Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button disabled={!subscribeEnable} onClick={(e) => subscribeHandler()} className="btn btn-outline-secondary" type="button" id="button-addon2">Subscribe</button>
                    </div>
                  </div>
                  <div className="copy text-end">
                    <p><Link to={'/privacy-policy'}>Privacy Policy</Link>  |  <Link to={"terms"}>Term and Condition</Link></p>
                  </div>
                </div>
                
                <div className="col-md-3 col-sm-12 p-0">
                  <div className="app">
                    <h3>Download Our App</h3>
                    <div className="app-img">
                      <img alt='' src={mobile} className="img-fluid img_mobile" />
                      <div>
                        <Link target='_blank' rel="noreferrer" to={'https://apps.apple.com/in/app/aksum/id6477289529'}><img alt='' src={apple} /></Link>
                        <a target='_blank' rel="noreferrer" href="https://play.google.com/store/apps/"><img alt='' src={google} /></a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

    </footer>
    );
  }
  
export default Footer;
  