import React, { useState } from 'react';
import useCart from '../../../utils/hooks/useCart';
import useUser from '../../../utils/hooks/useUser';
import { Link, useNavigate } from 'react-router-dom';
import {placeOrder} from '../../../utils/api';
import toast from 'react-hot-toast';
import BredCrum from '../../../components/BredCrum'
import {getToken} from '../../../utils/tokenHelper';

function Checkout() {
    const navigate = useNavigate();
    const { cart, setCart } = useCart();
    const { user } = useUser();
    const [ orderloc, setOrderLoc] = useState('');
    const token = getToken();

    function onChangeHandler(e){
        setOrderLoc(e.target.value);
    }

    const updateCart = (item_code, qty=0) => {
        let tempCart = cart == null ? [] : cart;

        var index = tempCart == null ? -1 : tempCart.findIndex(v => v.item_code === item_code);
        if(qty === 0){
            tempCart.splice(index, 1); 
        }else{
            tempCart[index].quantity = qty
        }
        setCart([...tempCart]);
    }
    const placeOrderHandler = async () => {
        if(orderloc.trim().length < 3){
            toast.error("Please enter your location!", {position: 'top-center'})
        }else{
            try {
                const payload = {
                    "products": {
                        "list": cart
                    },
                    "location": orderloc
                }
                // eslint-disable-next-line
                const res = await placeOrder(payload, token);
                setCart([])
                toast.success("Order placed successfully", {position: 'top-center'})
                navigate("/user/buyer/opportunity")

            }catch(err){
                console.log(err);
            }
        }
    }
    return (
        <div className="container">
        <BredCrum><Link to={`/`} >Home</Link> / Checkout</BredCrum>
            <div className='common-heading mt-3 mb-2'>
                <h1> Checkout </h1>
            </div>
            {
                cart.length ? 
                <>
                    <div className='row'>
                        <div className='col'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <td>Item Name</td>
                                        <td>Category</td>
                                        <td>Qty</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart.map((row, i) => {
                                        return (
                                            <tr key={`${row.item_name}_${row.item_code}`}>
                                                <td>{row.item_name}</td>
                                                <td>{row.category}</td>
                                                <td>
                                                    <input type='number' className='form-control input_mw_100' value={row.quantity} onChange={(e) => updateCart(row.item_code, e.target.value)} />
                                                </td>
                                                <td>
                                                    <button onClick={() => updateCart(row.item_code)} className='btn btn-danger'>Remove</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : <>
                    <p>Currently there is product in Cart.</p>
                </>
            }
            <div className='row'>
                <div className='col'>
                    {
                         user == null ? 
                        <div className='row'>
                            <div className='col-md-6 col-sm-12 text-sm-center text-md-start  mb-4'>
                               <Link className='btn btn-primary' to={'/cats'}>Continue Shopping</Link> 
                            </div>
                            <div className='col-md-6 col-sm-12 text-md-end text-sm-center  mb-4'>
                               <Link className='btn btn-outline-primary' to={'/login'}>Login</Link> 
                            </div>
                        </div>
                        : 
                        <div>
                            {
                                cart.length ? 
                                <>
                                <div className='row mt-3'>
                                    <div className='col-md-10'>
                                        <input className='form-control mb-2' name="orderloc" placeholder='Please enter your location' onChange={(e) => onChangeHandler(e)} />
                                    </div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-info w-100' onClick={() => placeOrderHandler()}>Place Order</button>
                                    </div>
                                </div>
                                </>
                                :
                                <></>
                            }
                            <div className='row mt-4'>
                                <div className='col-md-12 col-sm-12 text-md-end text-sm-center  mb-4'>
                                    <Link className='btn btn-primary' to={'/cats'}>Continue Shopping</Link> 
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
  }
  
export default Checkout;
