import React from 'react';
import useCart from '../utils/hooks/useCart'
import useUser from '../utils/hooks/useUser'
import toast from "react-hot-toast";


function ProductCard({product}) {
    const { user } = useUser();
    const { cart = [], setCart } = useCart();
    const addToCart = (product) => {
        const tempCart = cart === null ? [] : cart;
        const {item_code, item_name, category = ''} = product;

        var index = tempCart === null ? -1 : tempCart.findIndex(v => v.item_code === item_code);
        if(index > -1){
            tempCart[index].quantity = parseInt(tempCart[index].quantity) + 1;
        }else{
            tempCart.push({
                "item_code": item_code,
                "item_name": item_name,
                "quantity": 1,
                "category": category
            })
        }
        setCart([...tempCart])
    }
    const updateCart = (obj) => {
        const tempCart = cart === null ? [] : cart;
        const {item_code, action} = obj;

        var index = tempCart === null ? -1 : tempCart.findIndex(v => v.item_code === item_code);

        if(action === "-"){
            if(index > -1){
                if(tempCart[index].quantity === 1){
                    tempCart.splice(index, 1);
                }else{
                    tempCart[index].quantity = parseInt(tempCart[index].quantity) - 1;
                }
            }
        }
        if(action === "+"){
            if(index > -1){
                tempCart[index].quantity = parseInt(tempCart[index].quantity) + 1;
            }
        }
        setCart([...tempCart]);
    }
    
    function cartClickHandler(product){
        console.log(user)
        if((user !== null) && (user.type === "supplier" || user.type === "Supplier")){
            toast.error("Supplier can not add item in cart!", {position: 'top-center'})
        }else{
            addToCart(product)
        }
    }

    function showProduct(item, cart){
        if(cart !== undefined && cart.findIndex(v => v.item_code === product.item_code) > -1){
            const item_index = cart.findIndex(v => v.item_code === product.item_code);
            return(
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" key={product.item_code}>
                    <div className="card" >
                        <img className="mh" src={product.image.length ? product.image : "https://aksum.co.in/api/static/sub_category/LLDPE.jpeg"}  alt="..." />
                        <div className="card-body">
                            <h5 title={product.item_name} className="card-title">{product.item_name}</h5>
                            {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                            <div>
                                <button onClick={() => updateCart({'item_code': product.item_code, 'action': "-"})} className='btn btn-outline-secondary cart-btn'><span>-</span></button>
                                <span className='cartvalue'>{cart[item_index].quantity}</span>
                                <button onClick={() => updateCart({'item_code': product.item_code, 'action': "+"})} className='btn btn-outline-secondary cart-btn'><span>+</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" key={product.item_code}>
                    <div className="card" >
                        <img className="mh"  src={product.image.length ? product.image : "https://aksum.co.in/api/static/sub_category/LLDPE.jpeg"}  alt="..." />
                        <div className="card-body">
                            <h5 title={product.item_name} className="card-title">{product.item_name}</h5>
                            {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                            <button onClick={(e) => cartClickHandler(product)} className="btn btn-primary">Add to Cart</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {showProduct(product, cart == null ? [] : cart)}
        </>
    );
}

export default ProductCard;
