import { useContext, useEffect } from "react";
import { UserContext } from "./UserContext";
import { removeToken, removeUserAndToken } from "../tokenHelper";
import { useNavigate } from "react-router-dom";

const useUser = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();

  if (!context) {
    throw new Error("useUser hook must be called inside UserContext");
  }

  const { user, setUser, clearUser } = context;

  const logout = () => {
    removeToken();
    removeUserAndToken();
    clearUser();
    navigate("/login");
  };

  useEffect(() => {
    if (user) localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return { user, setUser, clearUser, logout };
};

export default useUser;
