import React, { useEffect, useState  } from 'react';
import {
    getPurchaseOrders,
    downloadPDF
  } from "../../../../utils/api"
import toast from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTable } from 'react-table';
import TableLoading from '../../../../components/TableLoading';
import {getToken} from '../../../../utils/tokenHelper'


function Orders() {
    const rowLen = 10;
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [orders, setOrders] = useState([]);
    const token = getToken();
    const [docType] = useState('Sales Order');

    const columns = React.useMemo(
        () => [
            {
                id: "name",
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: "delivery_date",
                Header: 'Delivery date',
                accessor: 'delivery_date',
            },
            {
                id: "grand_total",
                Header: 'Grand Total',
                accessor: 'grand_total',
            },
            {
                id: "total_qty",
                Header: 'Total Qty',
                accessor: 'total_qty',
            },
            {
                id: "status",
                Header: 'Status',
                accessor: 'status',
            },
            {
                id: "viewpo",
                Header: 'View PO',
                accessor: 'name',
            },
        ], []
    );
    const tableInstance = useTable({ columns, data: orders });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    async function loadInvoice() {
        try{
            const payload = {
                "name": "",
                "start": parseInt(0),
                "length": parseInt(rowLen)
             }
             
            const res = await getPurchaseOrders(token, payload)
            const {sales_orders} = res;
            setOrders(sales_orders);
            setStartIndex(startIndex+sales_orders.length)
            if(sales_orders.length < rowLen){
                setHasMore(false)
            }
        }catch(err){
            console.log(err)
        }
    }
    const fetchMoreData = () => {
        setTimeout(async () => {
            try{
                const payload = {
                    "name": "",
                    "start": parseInt(startIndex),
                    "length": parseInt(rowLen)
                 }
                 
                const res = await getPurchaseOrders(token, payload)
                const {sales_orders} = res;
                setOrders((prevItems) => [
                    ...prevItems,
                    ...sales_orders,
                ]);
                setStartIndex(startIndex+sales_orders.length)
                if(sales_orders.length < rowLen){
                    setHasMore(false)
                }
            }catch(err){
                console.log(err)
            }
        }, 500);
    };

    async function download_pdf(name){
        try{
            await downloadPDF(token, name, docType);
        }catch(err){
            let msg = `${docType} ${name} not found`;
            toast.error(msg, {position: 'top-center'});
        }
    }
    useEffect(() => {
        loadInvoice()
    }, [])
    return (
        <section>
            <div className="row mt-2 mb-5">
                <div id='scrollableDiv' style={{ height: '80vh', overflow: 'auto' }}>
                    <InfiniteScroll
                        dataLength={orders.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<TableLoading />}
                        scrollableTarget='scrollableDiv'
                    >
                        <table {...getTableProps()} className='table table-striped'>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, k) => {
                                            if(k === 5){
                                                return(
                                                    <td {...cell.getCellProps()}>
                                                        <button onClick={() => download_pdf(cell.render('Cell').props.value)} className='btn btn-primary'> {`View PO`}</button>
                                                    </td>
                                                )
                                            }else{
                                                return(
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            }
                                        })}
                                    </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </section>
    );
  }
  
export default Orders;
