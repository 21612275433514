import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import Products from './Products';
import BredCrum from '../../../../components/BredCrum';
import BusinessCard from './BusinessCard'
import { getBusinessLines, getNameBySlug } from '../../../../utils/api';

function Business() {
    const {businessname = ''} = useParams();
    const [business_name, setBusinessName] = useState('')
    // eslint-disable-next-line
    const rowLen = 20;
    // eslint-disable-next-line
    const [hasMore, setHasMore] = useState(false);
    // eslint-disable-next-line
    const [startIndex, setStartIndex] = useState(0);
    const [businesslist, setBusinessList] = useState([]);
    async function loadBusiness(){
        try{
            const resp = await getBusinessLines();
            setBusinessList(resp)
        }catch(err){
            console.log(err)
        }
    }
    async function getBusinessNameBySlug(){
        try{
            const resp = await getNameBySlug(businessname, 'Business Line');
            setBusinessName(resp)
        }catch(err){
            console.log(err)
        }
    }
    async function loadMoreBusiness(){

    }
    useEffect(() => {
        if(businessname.length === 0){
            setBusinessList([])
        }else{
            loadBusiness()
            getBusinessNameBySlug()
        }
    }, [businessname])
    return (
        <>
            <div className="container-fluid">
                <BredCrum>
                    <Link to={`/`} >Home</Link> / {business_name}
                </BredCrum>
                {  businessname.length !== 0 ?
                <>
                    <h3 className='mt-2'>{businessname}</h3>
                    <Products businessname={businessname} />
                </>
                :
                    <div className='my-3'>
                        <div className="row cnt_products">
                            {
                                businesslist.map((item, index) => {
                                    return <BusinessCard key={`${item.business_line}_${index}`} item={item} />        
                                })
                            }
                        </div>

                        {
                            hasMore ? <>
                                <div className='row mb-3 mt-4'>
                                    <div className='col-md-12 text-center'>
                                        <button onClick={() => loadMoreBusiness()} className='btn btn-outline-primary'>Load more</button>
                                    </div>
                                </div>
                            </>:<></>
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default Business;
