import React, { useState } from "react";
import { Outlet, ScrollRestoration, useMatch, Navigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { UserContext } from "../../utils/hooks/UserContext";
import { CartContext } from "../../utils/hooks/CartContext";
import useUser from "../../utils/hooks/useUser";

function AppLayout() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const clearUser = () => {
    setUser(undefined);
    localStorage.removeItem("user");
  };

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart"))
  );
  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <UserContext.Provider value={{ user, setUser, clearUser }}>
      <CartContext.Provider value={{ cart, setCart, clearCart }}>
        <Header />
        <AuthCheck />
        <Footer />
        <ScrollRestoration />
      </CartContext.Provider>
    </UserContext.Provider>
  );
}

export default AppLayout;

const AuthCheck = () => {
  const { user } = useUser();
  const isPrivate = useMatch("user/*");
  if (!isPrivate || user){
    return <div className="min-height"><Outlet></Outlet></div>
  }else{
    return <Navigate to="/login" replace />
  }
};
