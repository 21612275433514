import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Otp from "../../components/Otp";
import { useForm } from "react-hook-form";
import { registerUser } from "../../utils/api";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { isPasswordStrong } from "../../utils/helper";
import useUser from "../../utils/hooks/useUser";
import { saveToken } from "../../utils/tokenHelper";

export const SignupContext = React.createContext(
  null
);

function SignUp() {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [step, setStep] = useState(1);
  const [formState, setFormState] = useState();
  // eslint-disable-next-line
  const [isLoading, setisLoading] = useState(false);
  // eslint-disable-next-line
  const [isError, setisError] = useState(false);

  const onSubmitForm = (data) => {
    setFormState(data);
    setStep(2);
  };
  const handleRegistration = (resp) => {
    console.log(resp)

    const {sid} = resp;
    saveToken(`${sid}`)
    setUser({
        address: '', 
        cin: '', 
        company_name: resp.company_name, 
        email: resp.email, 
        gst: '', 
        name: resp.full_name, 
        pan: '', 
        phone: resp.phone, 
        turnover:'',
        type: resp.register_as
    });
    
    navigate("/user/profile", { replace: true });
  };

  const resendOTPHandler = async () => {
    const {email, company_name, pwd, phone, register_as, full_name} = formState;
    let payload = {
      "register_as": register_as, // Can be “Supplier”
      "company_name": company_name,
      "full_name": full_name,
      "email": email,
      "pwd": pwd,
      "phone": phone,
      "otp": "",
      "tmp_id": ""
    }
    console.log(payload);
    //registerUser()
    const resp = await registerUser(payload);
    console.log(resp);
    toast.success("OTP re-sent!", {position: 'top-center'});
  }

  return (
    <SignupContext.Provider value={{ formState, onSubmitForm }}>
      <>
        {step === 1 && <Form />}
        {step === 2 && formState && (
          <>
            <Otp
              payload={formState}
              onBack={() => {
                setStep(1);
              }}
              onSuccess={handleRegistration}
              onResendOTP={() => {
                resendOTPHandler()
              }}
            />
          </>
        )}
        {step === 3 && (
          <div className="text-center">
            {isLoading && <p>Sign-up on progress. Please wait...</p>}
            {isError && (
              <>
                <p>Failed to sign you up! Please go back and try again!</p>
                <button
                  onClick={() => setStep(1)}
                  className="btn mx-auto mt-3 block">
                  Back
                </button>
              </>
            )}
          </div>
        )}
      </>
    </SignupContext.Provider>
  );
}

const Form = () => {
  const [checked, setChecked] = useState(false);
  const { formState, onSubmitForm } = useContext(SignupContext) ?? {};
  const { register, handleSubmit, watch } = useForm({
    defaultValues: formState,
  });
  const [verified, setVerified] = useState(false);

  const pw = watch("pwd");

  const isStrong = isPasswordStrong(pw);

  const onSubmit = async (data) => {
    try{
      if (!isStrong) {
        toast.error("Password is not strong enough!", {position: 'top-center'});
        return;
      }
      // if (!checked) {
      //   toast.error("You must accept terms and conditions first!", {position: 'top-center'});
      //   return;
      // }
      if (data.pwd !== data.cnfPassword) {
        toast.error("Passwords did not match", {position: 'top-center'});
        return;
      }
      // console.log('+++', data)
      //onSubmitForm && onSubmitForm(data);
      
      const {email, company_name, pwd, phone, register_as, full_name} = data;
      let payload = {
        "register_as": register_as, // Can be “Supplier”
        "company_name": company_name,
        "full_name": full_name,
        "email": email,
        "pwd": pwd,
        "phone": phone,
        "otp": "",
        "tmp_id": ""
      }
      console.log(payload);
      //registerUser()
      const resp = await registerUser(payload);
      console.log(resp);
      toast.success("OTP sent!", {position: 'top-center'});
      //knavigate("/login")
      payload["tmp_id"] = resp['tmp_id']
      onSubmitForm && onSubmitForm(payload);
    }catch(err){
      console.log(err);
      console.log(err.response.data.message.message)
      toast.error(err.response.data.message.message, {position: 'top-center'});
    }
  };

  return (
    <div className="container">
        <div className="row justify-content-center mt-5 mb-5">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="login-box">
                <div className='common-heading mt-2 mb-2'>
        <h1>
                        Sign Up
                    </h1>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="flex flex-col gap-5">
                            <div className="form-group  mb-4 select-none  gap-4">
                                <label className="form-check form-check-inline ps-0">
                                   <strong> Register as:</strong>
                                </label>

                                <label className="form-check form-check-inline gap-1">
                                    <input
                                        {...register("register_as")}
                                        value="Customer"
                                        type="radio"
                                        required
                                        className="form-check-input"
                                    />
                                    <span>Customer</span>
                                </label>
                                <label className="form-check form-check-inline gap-1">
                                    <input
                                        {...register("register_as")}
                                        value="Supplier"
                                        required
                                        type="radio"
                                        className="form-check-input"
                                    />
                                    <span>Supplier</span>
                                </label>
                            </div>
                            <input
                                {...register("company_name")}
                                type="text"
                                required
                                placeholder="Organization Name"
                                className="form-control mb-4"
                            />
                            <input
                                {...register("email")}
                                type="email"
                                required
                                placeholder="Email address"
                                className="form-control mb-4"
                            />
                            <input
                                {...register("full_name")}
                                type="text"
                                required
                                placeholder="Full name"
                                className="form-control mb-4"
                            />
                            <div className="input-group mb-4">
                                <span className="input-group-text" id="basic-addon1">+91</span>
                                <input
                                    {...register("phone")}
                                    pattern="[0-9]{10}"
                                    maxLength={10}
                                    type="text"
                                    required
                                    placeholder="Phone Number"
                                    className="form-control    w-full pl-24"
                                />
                            </div>
                            <input
                                {...register("pwd")}
                                type="password"
                                required
                                placeholder="Password"
                                className=" mb-4 form-control "
                            />
                            <input
                                {...register("cnfPassword")}
                                type="password"
                                required
                                placeholder="Confirm Password"
                                className=" mb-4 form-control "
                            />
                            {!isStrong && (
                                <div>
                                    <p className="font-medium">Password guidelines:</p>
                                    <ul className="mt-1 list-inside list-disc text-sm">
                                        <li>At least 6 characters</li>
                                        <li>A mixture of both uppercase and lowercase letters</li>
                                        <li>A mixture of letters and numbers</li>
                                    </ul>
                                </div>
                            )}
                            <div style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}>
                                <ReCAPTCHA
                                    sitekey="6LcYHugiAAAAALt9HDcid_sslL4YjHYOPGYxucDs"
                                    onChange={() => setVerified(true)}
                                />
                            </div>
                            {/* <div className="flex gap-1  mb-4">
                                <div className="flex select-none items-center gap-2">
                                <input
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                    type="checkbox"
                                    className="checkbox me-2"
                                />
                                <Link className="hover:underline underline-offset-4" target="_blank" to="/terms">
                                    Terms and condition apply
                                </Link>
                                </div>
                            </div> */}
                            <button disabled={!verified || !isStrong} className="btn btn-primary w-100 mb-4">
                                Sign Up
                            </button>
                            <div className="text-center font-medium">
                                {"Already have an account? "}
                                <Link to="/login" className="text-blue-400">
                                Sign In
                                </Link>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
  );
};

export default SignUp;
