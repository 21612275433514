import React, { useEffect, useState } from 'react';

function UploadQuotation({title, openModal, modalCloseHandler, uploadClickHandler}) {
    const [uploadFile, setUploadFile] = useState(null);
    let classes = 'modal';
    let styles = { };

    if(openModal){
        classes = 'modal fade show';
        styles = {display: 'block'}
    }
    function uploadClick(){
        uploadClickHandler(uploadFile)
    }
    function closeHandler(){
        setUploadFile(null)
        modalCloseHandler(uploadFile);
    }
    function handleFile(e) { 
        let file = e.target.files[0]; 
        setUploadFile(file); 
    } 

    useEffect(() => {

        return () => {
            setUploadFile(null);
        };
    }, []);
    return (
        <div className={classes} style={styles} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button onClick={() => closeHandler()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col'>
                                <p>You can upload pdf, jpeg, jpg, png files only.</p>
                            </div>
                       </div>
                       <div className='row'>
                            <div className='col'>
                                <input accept='application/pdf, image/jpeg, image/jpg, image/png' onChange={(e) => handleFile(e)} type='file' name="file" className='form-control' />
                            </div>
                       </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => closeHandler()} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button disabled = {uploadFile == null} onClick={() => uploadClick()} type="button" className="btn btn-primary">Upload Quotation</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
export default UploadQuotation;
  