import {
    createBrowserRouter,
    Link,
    RouterProvider,
  } from "react-router-dom";

  import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
  import React from "react";
  import { Toaster } from "react-hot-toast";
  import AppLayout from "../components/layouts/AppLayout";
  import Dashboard from "../containers/dashboard/Default.js";
  import Category from "../containers/dashboard/pages/Category/Index.js";

  import Login from "../containers/account/Login.js";
  import Signup from "../containers/account/Signup.js";
  import Contact from "../containers/dashboard/pages/Contact.js";
  import About from "../containers/dashboard/pages/About.js";
  import Media from "../containers/dashboard/pages/Media.js";
  import UserIndex from '../containers/dashboard/user/Index.js'
  import ChangePassword from '../containers/dashboard/user/ChangePassword.js'
  import Profile from "../containers/dashboard/user/Profile.js";
  import ForgotPassword from "../containers/account/ForgotPassword.js";

  import Invoice from "../containers/dashboard/user/buyer/Invoice.js";
  import Opportunity from "../containers/dashboard/user/buyer/Opportunity.js";
  import Orders from "../containers/dashboard/user/buyer/Orders.js";
  import Quotation from "../containers/dashboard/user/buyer/Quotation.js";

  import RequestForQuotation from "../containers/dashboard/user/supplier/RequestForQuotation.js";
  import PurchaseInvoice from "../containers/dashboard/user/supplier/PurchaseInvoice.js";
  import PurchaseOrders from "../containers/dashboard/user/supplier/PurchaseOrders.js";
  import SupplierQuotation from "../containers/dashboard/user/supplier/SupplierQuotation.js";
  import Checkout from "../containers/dashboard/pages/Checkout.js";


  import Terms from "../containers/dashboard/pages/Terms.js";
  import PrivacyPolicy from "../containers/dashboard/pages/PrivacyPolicy.js";
  import Search from "../containers/dashboard/pages/Search.js";
  import Brand from "../containers/dashboard/pages/Brand/Index.js";
  import Business from "../containers/dashboard/pages/Business/Index.js";
  import OurTeam from "../containers/dashboard/pages/OurTeam.js";
  import Careers from "../containers/dashboard/pages/Careers.js";
  import SupplyChain from "../containers/dashboard/pages/SupplyChain.js";
  import Logistic from "../containers/dashboard/pages/Logistic.js";
  import Financing from "../containers/dashboard/pages/Financing.js";
  import FAQ from "../containers/dashboard/pages/FAQ.js";
  

  
  function Root() {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    });
  
    const router = createBrowserRouter([
      {
        path: "/",
        element: <AppLayout />,
        errorElement: (
          <>
            <div className="flex h-screen flex-col items-center justify-center">
              <span className="text-6xl font-black">Error</span>
              <p className="mb-4 mt-1">We could not process your request.</p>
              <Link to="/" className="btn">
                Home
              </Link>
            </div>
          </>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: "contact-us",
            element: <Contact />,
          },
          {
            path: "terms",
            element: <Terms />,
          },
          {
            path: "privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "about-us",
            element: <About />,
          },
          {
            path: "media",
            element: <Media />,
          },
          {
            path: "cats/:catname?/:subcatname?",
            element: <Category />,
          },
          {
            path: "brand/:brandname?",
            element: <Brand />,
          },
          {
            path: "business/:businessname?",
            element: <Business />,
          },
          {
            path: "search/:keywords?",
            element: <Search />,
          },
          {
            path: "checkout",
            element: <Checkout />,
          },
          {
            path: "careers",
            element: <Careers />,
          },
          {
            path: "our-team",
            element: <OurTeam />,
          },
          {
            path: "/supply-chain",
            element: <SupplyChain />,
          },
          {
            path: "/financing",
            element: <Financing />,
          },
          {
            path: "/logistic",
            element: <Logistic />,
          },
          {
            path: "/faq",
            element: <FAQ />,
          },
          {
            path: 'user',
            element: <UserIndex />,
            children:[
              {
                path: "profile",
                element: <Profile />,
              },
              {
                path: "change-password",
                element: <ChangePassword />,
              },
              {
                path: "buyer/opportunity",
                element: <Opportunity />,
              },
              {
                path: "buyer/invoice",
                element: <Invoice />,
              },
              {
                path: "buyer/orders",
                element: <Orders />,
              },
              {
                path: "buyer/quotation",
                element: <Quotation />,
              },
              {
                path: "supplier/request-quotation",
                element: <RequestForQuotation />,
              },
              {
                path: "supplier/sales-invoice",
                element: <PurchaseInvoice />,
              },
              {
                path: "supplier/sales-orders",
                element: <PurchaseOrders />,
              },
              {
                path: "supplier/quotation",
                element: <SupplierQuotation />,
              },
            ]
          },
          {
            path: "/login",
            element: <Login/>
          },
          {
            path: "/sign-up",
            element: <Signup/>
          },
          {
            path: "/forgot-password",
            element: <ForgotPassword/>
          }
        ]
      },
    ]);
  
    return (
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <Toaster position="top-center" />
        </QueryClientProvider>
    );
  }
  
  export default Root;