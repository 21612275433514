import React, { useEffect, useState } from "react";
import {
  get_profile,
  update_profile,
} from "../../../utils/api";
import {getToken} from '../../../utils/tokenHelper'
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useUser from "../../../utils/hooks/useUser";
import {validateCIN, isValidGSTNo, isValidPanCardNo} from '../../../utils/helper'


function Profile() {
  const {  setUser } = useUser();
  const { register, handleSubmit, reset } = useForm();
  const [profileLoading, setProfileLoading] = useState(true); 
  
  const onSubmit = async (data) => {
    // if (!isValidGSTNo(data.gst)) {
    //   toast.error("Invalid GST Number", {position: 'top-center'});
    //   return;
    // }
    // if (!validateCIN(data.cin)) {
    //   toast.error("Invalid CIN Number", {position: 'top-center'});
    //   return;
    // }
    // if (!isValidPanCardNo(data.pan)) {
    //   toast.error("Invalid PAN Number", {position: 'top-center'});
    //   return;
    // }


    if (data.name == "") {
      toast.error("Invalid Name", {position: 'top-center'});
      return;
    }
    if (data.phone == "") {
      toast.error("Invalid Phone", {position: 'top-center'});
      return;
    }
    if (data.email == "") {
      toast.error("Invalid Email", {position: 'top-center'});
      return;
    }

    const token = getToken();
    try{
      // eslint-disable-next-line
      const resp = await update_profile(token, data)
      setUser({...data})
      toast.success("Profile updated successfully.", {position: 'top-center'})
    }catch(err){
      console.log(err)
    }
  };

  async function getdata(){
    const token = getToken();

    try{
      const res = await get_profile(token);
      setProfileLoading(false);
      reset(res);
    }catch(err){
      console.log(err)
      setProfileLoading(false);
    }
  }
  useEffect(() => {
    getdata()
  }, [])


  const loading = profileLoading //|| profileUpdating;
  //error && console.log(error);

  return (
    <>
    <div className="container ">
      <div className="row justify-content-center mt-3">
        <div className='common-heading mt-2 mb-2'>
          <h1>Profile</h1>
        </div>
          <div className="login-box mb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={loading}>
                <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <div className="col-12">
                    <div className="mb-1 fw-bold">Name*</div>
                    <input
                      required
                      {...register("name")}
                      type="text"
                      placeholder="Your Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12">
                  <div className="mb-1 fw-bold mt-3">PAN No.*</div>
                    <input
                      required
                      {...register("pan")}
                      type="text"
                      placeholder="PAN No."
                      className="form-control"
                    />
                  </div>
                  <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">Company Name*</div>
                          <input
                            required
                            {...register("company_name")}
                            type="text"
                            disabled
                            placeholder="Company Name"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">GST No.*</div>
                          <input
                            required
                            {...register("gst")}
                            type="text"
                            placeholder="GST No."
                            className="form-control"
                          />
                        </div>
                        <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">Phone*</div>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text"> +91</div>
                            </div>
                            <input
                              required
                              {...register("phone")}
                              type="text"
                              pattern="[0-9]{10}"
                              maxLength={10}
                              placeholder="Phone Number"
                              className="form-control pl-24"
                            />      
                            </div>
                          {/* <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center border-r-2 border-primary-400 px-6 text-lg font-semibold">
                              +91
                            </span>
                            <input
                              required
                              {...register("phone")}
                              type="text"
                              pattern="[0-9]{10}"
                              maxLength={10}
                              placeholder="Phone Number"
                              className="form-control pl-24"
                            />
                          </div> */}
                        </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
          
                    
                        
                        
                        <div className="col-12">
                          <div className="mb-1 fw-bold ">CIN No.*</div>
                          <input
                            required
                            {...register("cin")}
                            type="text"
                            placeholder="CIN No."
                            className="form-control"
                          />
                        </div>
                        <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">Email*</div>
                          <input
                            {...register("email")}
                            type="text"
                            disabled
                            placeholder="Email"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">Turnover Last year (Cr.)*</div>
                          <input
                            required
                            {...register("turnover")}
                            type="text"
                            placeholder="Turnover Last year (Cr.)"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12">
                          <div className="mb-1 fw-bold mt-3">Address*</div>
                          <textarea
                            required
                            {...register("address")}
                            className="form-control resize-none lg:col-span-2"
                            placeholder="Address"
                            rows={4}
                          />
                        </div>
                        <div className="col-12 mt-4 flex">
                          <button className="btn btn-outline-primary w-100">
                            {loading ? "Please Wait" : "Submit"}
                          </button>
                        </div>
                      </div>
                      
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
