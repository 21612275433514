import React from 'react';
import { Link } from 'react-router-dom';


function NewsCard({ item }) {
    return (
      <div className='col-md-4 col-sm-6 col-xs-12'>
        <Link
            data-img = {item.image}
            to={item.url}
            target="_blank"
            rel="noreferrer"
            className="news_card d-block text-center mb-3">
            <img
                src={item.image.length ? item.image : 'https://aksum.co.in/api/static/news/news1.png'}
                className="h-40 w-full object-cover object-top"
                alt=""
            />
            <div className="p-2 tracking-normal">
                <div className="flex items-center gap-2 text-orange-400"></div>
                <h5 className="mt-2 text-start">{item.title}</h5>
                <p className="mt-2 mb-1 small text-start text-muted">
                    {item.date + " " + item.month}
                </p>
            </div>
        </Link>
      </div>
    );
}
export default NewsCard;