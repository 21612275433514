import {useEffect, useState} from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactSimplyCarousel from 'react-simply-carousel';

import google from '../../assets/banner_1.png';
import brand from '../../assets/brand.png';
import product from '../../assets/product.png';
import staller from '../../assets/staller.png';
import bulb from '../../assets/bulb.png';
import steel from '../../assets/steel.png';
import media from '../../assets/media.jpg';

import {getBusinessLines, getCategories, getUIResource} from '../../utils/api';
import { Link } from 'react-router-dom';
import NewsCard from '../../components/NewsCard';
import Requirement from '../../components/home/Requirement';
import RequirementV from '../../components/home/RequirementV';


function Dashboard() {
  const [cats, setCats] = useState([]);
  const [businessLines, setBusinessLines] = useState([]);
  const [banners, setBanners] = useState([]);   
  const [news, setNews] = useState([]);
  const [sections, setSections] = useState([]);
  const [activeSlideIndex1, setActiveSlideIndex1] = useState(0);
  const [activeSlideIndex2, setActiveSlideIndex2] = useState(0);
  const [activeSlideIndex3, setActiveSlideIndex3] = useState(0);

  async function loadCats(){
    try{
      const resp = await getCategories();
      setCats(resp.categories);
    }catch(err){
      console.log(err)
    }
  }
  async function loadBusinessLines(){
    try{
      const resp = await getBusinessLines();
      setBusinessLines(resp);
    }catch(err){
      console.log(err)
    }
  }



  async function getBannerData(){
    const resp = await getUIResource()
    let kdata = {}
    resp["Home"].forEach((item) => {
      if(!Object.keys(kdata).includes(item.section_name)){
        kdata[item.section_name] = [];
      }
      kdata[item.section_name].push(item)
    })
    setSections(kdata)
    setBanners(resp['Home Banner Image']);
    setNews(resp['Media'])
  }

  function getSection(key){
    if(key == "Achievement"){
      return(
        <></>
      )
    }
    if(sections[key].length){
      return(
        <section key={key} className="resource resource2">
          <div className="mx-2">
            <div className="common-heading">
              <h1>{key}</h1>
            </div>
            <div className="row">
              {
                sections[key].map((item) => 
                  <div className="col-md-4 col-sm-12 col-xs-12 mb-2" key={item.id}>
                    <div className="ra-common-box">
                      <img src={item.image.length ? item.image : media} className="img-fluid" />
                      <div className="ra-text">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <Link target='_blank' to={item.url}><i className="fas fa-caret-square-right"></i></Link>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </section>
      )
    }else{
      return(
        <></>
      )
    }
    
  }

  useEffect(() => {
    loadCats()
    getBannerData()
    loadBusinessLines()
  }, [])

  return (
    <>
      <div className="container-fluid">
        <div className='row'>
          <div className='col-md-9'>
    
          <Carousel 
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={10000}
        >
          {
            banners.map((banner) =>{
              if(banner.url == "Categories"){
                return(
                  <div key={banner}>
                    <Link className='d-block' to = {'/cats'} > 
                      <img src={banner.image} />
                    </Link>
                  </div>
                )
              }else if(banner.url == "Create RFQ"){
                return(
                  <div key={banner}>
                    <Link className='d-block' to = {'/checkout'} > 
                      <img src={banner.image} />
                    </Link>
                  </div>
                )
              }else{
                return(
                  <div key={banner}>
                    <img src={banner.image} />
                  </div>
                )
              }
            })
          }
        </Carousel>
          </div>
          <div className='col-md-3'>
          <RequirementV cats={cats} />
          </div>
        </div>
      </div>
      <div className="container">
        <section className="carousel categories">
          <div className='mx-2'>
            <div className="common-heading">
              <div className='row'>
                <div className='col-md-6'>
                  <h1>Popular Categories</h1>
                </div>
                <div className='col-md-6 text-end'>
                  <Link to={'/cats'}>View All</Link>
                </div>
              </div>
            </div>
            {cats.length ? 
            <div className='carousel_cnt'>
              <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex2}
                onRequestChange={setActiveSlideIndex2}
                // itemsToShow={5}
                itemsToScroll={1}
                forwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                  },
                  className: 'next',
                  children: <span>{`›`}</span>,
                }}
                backwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                  },
                  className: 'prev',
                  children: <span>{`‹`}</span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    minWidth: 410,
                  },
                  {
                    itemsToShow: 5 ,
                    itemsToScroll: 1,
                    minWidth: 1024,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                {
                  cats.map((cat) =>
                    <div className='product-box' key={cat.name}>
                      <div className="item">
                        <Link to={`/cats/${cat.slug}`}>
                          <img src={cat.image != null ? cat.image : product} className="img-fluid" />
                          <p>{cat.name}</p>
                        </Link>
                      </div>  
                    </div>
                  )
                }
              </ReactSimplyCarousel>
            </div>
          : <></> }
          </div>
        </section>
      </div>

      {/* <div className="container">
        <section className="carousel categories">
          <div className='mx-2'>
            <div className="common-heading">
              <div className='row'>
                <div className='col-md-6'>
                  <h1>Shop By Business Lines</h1>
                </div>
              </div>
            </div>
            {businessLines.length ? 
            <div className='carousel_cnt'>
              <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex3}
                onRequestChange={setActiveSlideIndex3}
                // itemsToShow={5}
                itemsToScroll={1}
                forwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                  },
                  className: 'next',
                  children: <span>{`›`}</span>,
                }}
                backwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                  },
                  className: 'prev',
                  children: <span>{`‹`}</span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    minWidth: 410,
                  },
                  {
                    itemsToShow: 5 ,
                    itemsToScroll: 1,
                    minWidth: 1024,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                {
                  businessLines.map((item) =>
                    <div className='product-box' key={item.business_line}>
                      <div className="item">
                        <Link to={`/business/${item.slug}`}>
                          <img src={item.image != null ? item.image : product} className="img-fluid" />
                          <p>{item.business_line}</p>
                        </Link>
                      </div>  
                    </div>
                  )
                }
              </ReactSimplyCarousel>
            </div>
          : <></> }
          </div>
        </section>
      </div> */}
      
      <div className="container-fluid px-0">
        <section className="product-detail">
          <div className="container">
            <div className="row">
              <div className="col-md-8  ">
                <div className="best">
                  <h3>Best Product Deals</h3>
                  <p>Connect with us for best deals on Steel , Polymer, Chemicals and many more.</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="bulb">
                  <img src={steel} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-2">
                <div className="shop-now">
                  <Link to={'/cats/steel'}>Shop Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        <div className="container px-0">
        {
          Object.keys(sections)?.map((key) => getSection(key))
        }

        <Requirement cats={cats} />  
      
        <div className="container">
        <section className="media">
          <div className=' mx-2'>
            <div className="common-heading">
              <h1>Media</h1>
            </div>
            <div className='row'>
                {news?.map((item) => (
                    <NewsCard key={item.id} item={item} />
                ))}
            </div>
          </div>
        </section>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
