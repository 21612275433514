import React, { useEffect, useState } from 'react';
import NewsCard from '../../../components/NewsCard';
import { Link } from 'react-router-dom';
import {getUIResource} from '../../../utils/api';
import BredCrum from '../../../components/BredCrum';

function Media() {
    const [news, setNews] = useState([]);

    async function loadData(){
      const res = await getUIResource();
      const media = res['Media'];
      setNews(media);
    }

    useEffect(() => {
        loadData();
    }, [])
    return (
        <div className='container'>
        <div className="mx-2 cnt_media">
            <BredCrum><Link to={`/`} >Home</Link> / Media & News</BredCrum>
        <div className='common-heading mt-3 mb-2'>
        <h1>Media & News</h1>
        </div>
            <div className='row'>
                {news?.map((item) => (
                    <NewsCard key={item.id} item={item} />
                ))}
            </div>
        </div>
        </div>
    );
  }
  
export default Media;
