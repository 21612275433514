import React from 'react';
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum'

function Financing() {
    return (
      <div className='container'>
        <div className="mx-2 cnt_about_us">
          <BredCrum><Link to={`/`} >Home</Link> / Financing</BredCrum>
          <div className='common-heading mt-3 mb-0'>
            <h1>Financing</h1>
          </div>
          
        </div>
      </div>
    );
  }
  
export default Financing;
