import {useEffect, useState} from 'react';
import {getProductList} from '../../../../utils/api';
import ProductCard from '../../../../components/ProductCard'

function Products({businessname}) {
    const rowLen = 20;
    const [hasMore, setHasMore] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [productlist, setProducts] = useState([]);
    async function loadProducts(business_line){
        const payload = {
            "category": "",
            "brand": "",
            "business_line": business_line
        }
        const resp = await getProductList(payload);
        setProducts((prevItems) => [
            ...prevItems,
            ...resp,
        ]);
        setStartIndex(startIndex+resp.length)
        if(resp.length < rowLen){
            setHasMore(false)
        }else{
            setHasMore(true)
        }
    }
    useEffect(() => {
        loadProducts(businessname)
    }, [businessname])
    return (
        <>
            <div className="row cnt_products">
                {
                    productlist.map((product, index) => {
                        return <ProductCard key={`${product.item_code}_${index}`} product={product} />        
                    })
                }
            </div>
            {
                hasMore ? <>
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-12 text-center'>
                            <button onClick={() => loadProducts(businessname)} className='btn btn-outline-primary'>Load more</button>
                        </div>
                    </div>
                </>:<></>
            }
        </>
    );
}

export default Products;
