export const isPasswordStrong = (pw) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(pw);
};
  
export function isEmail(email) { 
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
} 

export function validateCIN(cin) {
    // Regular expression for a valid CIN number
    const regex = /^[UL]\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/;
    // Check if the CIN number matches the regular expression
    if (!regex.test(cin)) {
      return false;
    }
    // Return true if the CIN number is valid
    return true;
}

export function isValidGSTNo(gstNumber) {
  // GST number should be 15 characters long
  if (gstNumber.length !== 15) {
    return false;
  }

  // GST number format: 2 characters, 10 digits, 1 character, 2 digits
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  return gstRegex.test(gstNumber);
}

export function isValidPanCardNo(panCardNo) {
    // Regex to check valid
    // PAN Number
    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
 
    // if PAN Number 
    // is empty return false
    if (panCardNo == null) {
        return false
    }
 
    // Return true if the PAN NUMBER
    // matched the ReGex
    if (regex.test(panCardNo) == true) {
        return true
    }
    else {
        return false
    }
}

export function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
}