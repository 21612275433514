import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {sendContact} from '../../../utils/api'
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum';

function Contact() {
  const [isLoading, setLoading] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    const {name, company, email, phone, message} = data;
    try{  
      const payload = {
        "name": name,
        "company": company,
        "email": email,
        "phone": phone,
        "message": message
      }
     
      const resp = await sendContact(payload);
      console.log(resp);
      toast.success('Thanks for connecting with us', {position: 'top-center'})
      reset()
      
      setLoading(false)
    }catch(err){
      setLoading(false)
      console.log(err)
    }
  };
  return (
    
    <div className='container'>
    <div className="mx-2 cnt_contact mb-3 ">
      <BredCrum><Link to={`/`} >Home</Link> / Contact us</BredCrum>
      <div className='common-heading mt-3 mb-2'>
        <h1>Contact us</h1>
        </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h5 className="heading">HELP CENTER</h5>
          <p>Connect with us</p>
          <p className="heading mt-4"><b>Contact Information</b></p>
          <p>
            6B, Vandana Building, Tolstoy Road, <br/>
            Connaught Place, New Delhi - 110001 - INDIA<br/>
            Email - <a href="mailto:hello@aksum.co.in">hello@aksum.co.in</a><br/>
            Phone - <a href="tel:91-8826933166">91-8826933166</a>
          </p>
          <p className="heading mt-4"><b>Social Media</b></p>
          <ul className=' uldisc'>
            <li><Link target='_blank' to="https://www.facebook.com/aksumtrademart">Facebook</Link></li>
            <li><Link target='_blank' to="https://twitter.com/aksumtrademart">Twitter</Link></li>
            <li><Link target='_blank' to="https://in.linkedin.com/company/aksum-trademart-private-limited">Linkedin</Link></li>          </ul>
          <p className="heading mt-4"><b>Office Hours</b></p>
          <p className="heading mt-4">Monday to Friday: 10:00 am to 6:00 pm</p>
        </div>
        <div className="col-md-6 col-sm-12 mb-4">
          <h5 className="heading">Get in Touch with Us Today</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset
              disabled={isLoading}
              className="mt-12 flex flex-col gap-8">
              <input
                type="text"
                {...register("name")}
                required
                placeholder="Enter Full Name"
                className="form-control mb-3"
              />
              <input
                type="text"
                {...register("company")}
                required
                placeholder="Enter Company Name"
                className="form-control mb-3"
              />
              <input
                {...register("email")}
                type="email"
                required
                placeholder="Email address"
                className="form-control mb-3"
              />
              <div className="input-group mb-4">
                  <span className="input-group-text" id="basic-addon1">+91</span>
                  <input
                      {...register("phone")}
                      pattern="[0-9]{10}"
                      maxLength={10}
                      type="text"
                      required
                      placeholder="Phone Number"
                      className="form-control    w-full pl-24"
                  />
              </div>
              <textarea
                {...register("message")}
                rows={4}
                required
                placeholder="Write message"
                className="form-control mb-3"></textarea>
              <div className="flex flex-col-reverse gap-4 text-lg max-sm:flex-wrap lg:flex-row">
                <button type="reset" className="btn btn-outline-primary">
                  Reset
                </button>
                &nbsp;&nbsp;&nbsp;
                <button type="submit" className=" btn btn-primary">
                  {isLoading ? "Please wait..." : "Send Message"}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}
  
export default Contact;
