import {useEffect} from 'react';
import { Link } from 'react-router-dom';

function BusinessCard({item}) {
    useEffect(() => {
        
    }, [])
    return (
        <>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3">
                <div className="card" >
                    <img className="mh"  src={item.image.length ? item.image : "https://aksum.co.in/api/static/sub_category/LLDPE.jpeg"}  alt="..." />
                    <div className="card-body">
                        <h5 title={item.business_line} className="card-title">{item.business_line}</h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <Link to={`/business/${item.slug}`} className="btn btn-primary">View</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BusinessCard;
