import { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import toast from "react-hot-toast";
import carticon from '../assets/cart.png';
import menuicon from '../assets/menu.png';
import usericon from '../assets/user.png';
import { Link, useNavigate, useParams } from "react-router-dom";
import {getCategories, getBrands, getBusinessLines} from '../utils/api';
import useUser from '../utils/hooks/useUser'
import useCart from '../utils/hooks/useCart'


function Header() {
    const {keywords = ''} = useParams();
    const navigate = useNavigate();
    const {pathname} = window.location;

    const { user, logout } = useUser();
    const { cart, setCart } = useCart();
    const [searchtxt, setSearchTxt] = useState(keywords);
    const [cats, setCats] = useState([]);
    const [brands, setBrands] = useState([]);
    const [business, setBusiness] = useState([]);
    async function loadCategories(){
      const resp = await getCategories();
      setCats(resp.categories);
    }
    async function loadBrands(){
      const resp = await getBrands();
      setBrands(resp);
    }
    async function loadBusinesslines(){
      try{
        const resp = await getBusinessLines();
        setBusiness(resp);
      }catch(err){
        console.log(err)
      }
    }
    const logoutHandler = () => {
      setCart([])
      toast.success("Logged Out successfully!", {position: 'top-center'});
      logout()
    }

    function searchClickHandler(){
      console.log(searchtxt, window.location);
      let txt = searchtxt.split(" ").join("-")
      if(!pathname.includes('search')){
        navigate(`search/${txt}`);
      }else{
        window.location.href = `${window.location.origin}/search/${txt}`
      }
    }

    useEffect(() => {
      loadCategories()
      loadBrands()
      loadBusinesslines()
    }, [user])
    return (
      <header>
          <div className="top-menu">
            <div className="row m-0">
              <div className="col-md-2">
                <div className="logo">
                  <Link to={"/"}>
                    <img alt="" src={logo} className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className="col-md-8">
                <div className="search">
                  <div className="input-group">
                    <input value={searchtxt} onKeyDown={(event) => {if(event.key === 'Enter') { searchClickHandler() }} } onChange={(e) => setSearchTxt(e.target.value)} name="q" type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <button onClick={(e) => searchClickHandler()} className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fas fa-search"></i></button>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="cart">
                  <Link to={'/checkout'} className="cart-box">
                    <img alt="" src={carticon} />
                    {cart != null && cart.length ?
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {cart.length}
                      <span className="visually-hidden">cart</span>
                    </span>
                    : <></>
                    }
                  </Link>
                  <div className="dropdown profile">
                    <a className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="" src={usericon} />
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      {(user !== undefined && user !== null) ? 
                        <>
                          <li><Link onClick={() => logoutHandler()} className='dropdown-item'>Logout</Link></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><Link to={"/user/profile"} className='dropdown-item' >Profile</Link></li>
                        </> 
                        :
                        <>
                          <li><Link to={"/login"} className='dropdown-item' >Sign In</Link></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><Link to={"/sign-up"} className='dropdown-item' >Sign Up</Link></li>
                        </> 
                      }
                    </ul>
                  </div>
                  <a className="menuicon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                      <img alt="" src={menuicon} /></a>
                </div>
                
         
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg ak-menu">
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars"></i>
            </button> */}
            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown menu">
                  <Link onClick={(e) => {
                    e.preventDefault();
                  }}
                    className={pathname.split("/")[1] === "cats" ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle'}
                     href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Browse All Category
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {
                      cats.map((cat) =>
                        <li key={cat.name}>
                          <Link className='dropdown-item' to={`cats/${cat.slug}`}>{cat.name}</Link>
                        </li>
                      )
                    }
                  </ul>
                </li>
                {/* <li className="nav-item dropdown menu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Shop By</a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                          <li className='dropdown menu'>
                            <a className="dropdown-item" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false">Brands</a>
                            <ul className="dropdown-menu ul3" aria-labelledby="navbarDropdown3" >{
                              brands.map((item, index) =>
                                <li key={`${item.brand}_${index}`}>
                                  <Link className='dropdown-item' to={`brand/${item.slug}`}>{item.brand}</Link>
                                </li>
                              )
                            }
                            </ul>
                          </li>
                          <li className='dropdown menu'>
                            <a className="dropdown-item" href="#" id="navbarDropdown4" role="button" data-bs-toggle="dropdown" aria-expanded="false">Business Lines</a>
                            <ul className="dropdown-menu ul3 ul40" aria-labelledby="navbarDropdown4" >{
                              business.map((item, index) =>
                                <li key={`${item.business_line}_${index}`}>
                                  <Link className='dropdown-item' to={`business/${item.slug}`}>{item.business_line}</Link>
                                </li>
                              )
                            }</ul>
                          </li>
                      </ul>
                   </li> */}
                <li className="nav-item">
                  <Link className={pathname === "/" ? 'nav-link active' : 'nav-link'} to="/">Home</Link>
                </li>
                
                <li className='nav-item dropdown menu'>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown111" role="button" data-bs-toggle="dropdown" aria-expanded="false">About us</a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown111">
                    {/* <li><Link className="dropdown-item" to={"/about-us"}>About us</Link></li> */}
                    <li><Link className="dropdown-item" to={"/about-us"}>What we do</Link></li>
                    <li><Link className="dropdown-item" to={"/about-us"}>Why you should associate</Link></li>
                    <li><Link className="dropdown-item" to={"/our-team"}>Our Team</Link></li>
                    <li><Link className="dropdown-item" to={"/contact-us"}>Contact us</Link></li>
                    <li><Link className="dropdown-item" to={"/careers"}>Career</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className={pathname === "/media" ? 'nav-link active' : 'nav-link'} to="/media">Media</Link>
                </li>
                <li className="nav-item">
                  <Link className={pathname === "/contact-us" ? 'nav-link active' : 'nav-link'} to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            {/* </div> */}
          </nav>

          <div className="side-nav">
            

<div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Browse All Category</h5>
    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
                  <ul className="sidemenu" aria-labelledby="navbarDropdown">
                    {
                      cats.map((cat) =>
                        <li key={cat.name}>
                          <Link className='dropdown-item' to={`cats/${cat.slug}`}>{cat.name}</Link>
                        </li>
                      )
                    }
                  </ul>
  </div>
</div>
          </div>

      </header>
    );
  }
  
export default Header;
  