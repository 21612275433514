import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import Subcat from './Subcat';
import Products from './Products';
import {getCategories,getNameBySlug} from '../../../../utils/api'
import BredCrum from '../../../../components/BredCrum';

function Category() {
    const {catname = null, subcatname = null} = useParams();
    const [cats, setCats] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');

    async function loadCats(){
        try {
            const resp = await getCategories()
            setCats(resp['categories'])
        }catch(err){
            console.log(err)
        }
    }
    async function getCatName(){
        try{
            const resp = await getNameBySlug(catname, 'Category');
            setCategoryName(resp)
        }catch(err){
            console.log(err)
        }
    }
    async function getSubCatName(){
        if(subcatname !== null){
            try{
                const resp = await getNameBySlug(subcatname, 'Category');
                setSubCategoryName(resp)
            }catch(err){
                console.log(err)
            }
        }
    }
    useEffect(() => {
        loadCats()
        if(catname !== null){
            getCatName()
        }
        if(subcatname !== null){
            getSubCatName()
        }
    }, [catname, subcatname])
    return (
        <>
            <div className="container">
                <BredCrum>
                <Link to={`/`} >Home</Link> 
                {catname !== null ? <> / {subcatname !== null ? <><Link to={`/cats/${catname}`} >{categoryName}</Link> / {subCategoryName}</> : categoryName}</> : <></>}
                </BredCrum>
                
                
                {
                    catname !== null ?
                    <>
                    {subcatname == null ? <Subcat catname={catname} /> : <></>}
                    <Products catname={subcatname == null ? catname : subcatname} />
                    </>
                    :
                    <>
                        <div className='row mt-2'>
                            {
                                cats.map((cat, i) => {
                                    return(
                                        <div key={cat.name} className='col-md-3 '>
                                            <div className='card catbox'>
                                                <img className="card-img-top" src={cat.image} alt="" />
                                                <div className="card-body text-center">
                                                    <h5 className="card-title">{cat.name}</h5>
                                                    <Link className='btn btn-primary' to={`/cats/${cat.slug}`}>{cat.name}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default Category;
