import React from 'react';
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum'

function About() {
    return (
      <div className='container'>
        <div className="mx-2 cnt_about_us">
          <BredCrum><Link to={`/`} >Home</Link> / About us</BredCrum>

          <div className='row my-4'>
            <div className='col-md-6 col-sm-12 text-md-end text-sm-center mb-sm-3'>
              <Link to={'/about-us'} className='btn btn-info'>About us</Link>
            </div>
            <div className='col-md-6 col-sm-12 text-md-start text-sm-center'>
              <Link to={'/our-team'} className='btn btn-outline-info'>Our Team</Link>
            </div>
          </div>

          <div className='common-heading text-center my-4'>
            <h1>About us</h1>
          </div>

          <p>
          Aksum Trademart was founded in 2021 and headquartered in New Delhi. 
We are India’s first B2B SCaaS platform with focus on solving the supply
 chain issues faced by SME’s and the corporates. We are a “Virtual Purchase 
Department” for our clients. We are leveraging ScaaS to optimize supply
 chain operations across manufacturing and infrastructure sectors. 
Through building a digitized platform we will not only simplify commerce 
but also enable transparency in the end to end supply chain.          </p>


          <div className='row my-4'>
            <div className='col-md-6 col-sm-12 text-start p2-4'>
              <img alt="" className='img abt' src='https://st.depositphotos.com/1038076/4908/i/450/depositphotos_49080337-stock-photo-about-us.jpg' />
            </div>
            <div className='col-md-6 col-sm-12 text-start'>
              <h5>What we do</h5>
              <p>As India’s first B2B Scaas platform we assist SME’ s
 and corporates build in sufficient flexibility in their 
supply chain processes by developing a framework 
that helps them plan & execute their procurement 
operations and working capital management in 
a more efficient and flexible manner.</p>
            </div>
          </div>

          <div className='row my-4'>
            <div className='col-md-6 col-sm-12 text-start pb-2'>
              <h5>Why you should associate with us: </h5>
              <p>Aksum Trademart, India's first B2B Scaas platform, serves as a virtual purchase department for SMEs and corporates in the manufacturing and infrastructure sectors. Our innovative solutions optimize supply chain operations, offering flexibility and efficiency while enhancing transparency through a digitized platform. Partner with us to streamline procurement, drive growth, and revolutionize supply chain management. </p>
            </div>
            <div className='col-md-6 col-sm-12 text-start'>
              <img alt="" className='img abt' src='https://st.depositphotos.com/1038076/4908/i/450/depositphotos_49080337-stock-photo-about-us.jpg' />
            </div>
          </div>
          
        </div>
      </div>
    );
  }
  
export default About;
