import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getSubCategories} from '../../../../utils/api';
import ReactSimplyCarousel from 'react-simply-carousel';
import product from '../../../../assets/product.png';

function Subcat({catname}) {
    const [subcats, setSubCats] = useState([]);
    const [activeSlideIndex2, setActiveSlideIndex2] = useState(0);

    async function loadSubCats(category_name){
        const payload = {
            "category": category_name
        }
        const resp = await getSubCategories(payload);
        const {categories} = resp;
        setSubCats(categories);
    }

    useEffect(() => {
        loadSubCats(catname)
    }, [catname])
    return (
        <>
            {subcats.length ? <div className='row cnt_subcat'>
                <section className="carousel categories p-0 mb-3 mt-2">
                    <div className='mx-2'>
                        <div className="common-heading">
                            <div className='col-md-6'>
                                <h1>Sub Categories</h1>
                            </div>
                        </div>
                        {subcats.length ? 
                        <div className='carousel_cnt'>
                            <ReactSimplyCarousel
                                activeSlideIndex={activeSlideIndex2}
                                onRequestChange={setActiveSlideIndex2}
                                itemsToShow={5}
                                itemsToScroll={1}
                                forwardBtnProps={{
                                style: {
                                    alignSelf: 'center',
                                },
                                className: 'next',
                                children: <span>{`›`}</span>,
                                }}
                                backwardBtnProps={{
                                style: {
                                    alignSelf: 'center',
                                },
                                className: 'prev',
                                children: <span>{`‹`}</span>,
                                }}
                                 responsiveProps={[
                                    {
                                        itemsToShow: 1,
                                        itemsToScroll: 1,
                                        minWidth: 410,
                                      },
                                      {
                                        itemsToShow: 5 ,
                                        itemsToScroll: 1,
                                        minWidth: 1024,
                                      },
                             
                                ]}
                                speed={400}
                                easing="linear"
                            >
                                {
                                subcats.map((cat) =>
                                    <div className='product-box' key={cat.name}>
                                    <div className="item">
                                        <Link to={`/cats/${catname}/${cat.slug}`}>
                                        <img alt="" src={cat.image != null ? cat.image : product} className="img-fluid" />
                                        <p>{cat.name}</p>
                                        </Link>
                                    </div>  
                                    </div>
                                )
                                }
                            </ReactSimplyCarousel>
                        </div>
                    : <></> }
                    </div>
                    </section>
            </div>
            : <></>}
        </>
    );
}

export default Subcat;
