import React from 'react';
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum'
import f1 from '../../../assets/f1.png';
import f2 from '../../../assets/f2.png';

function OurTeam() {
    return (
      <div className='container'>
        <div className="mx-2 cnt_about_us">
          <BredCrum><Link to={`/`} >Home</Link> / Our Team</BredCrum>

          <div className='row my-4'>
            <div className='col-md-6 col-sm-12 text-md-end text-sm-center mb-sm-3'>
              <Link to={'/about-us'} className='btn btn-outline-info'>About us</Link>
            </div>
            <div className='col-md-6 col-sm-12 text-md-start text-sm-center'>
              <Link to={'/our-team'} className='btn btn-info'>Our Team</Link>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4 text-center'>
              <img alt='' className='profile_img mb-2' src = {f1} />
              <h4>Mr. Sumit Bhaita</h4>
              <p>Co-Founder</p>
            </div>
            <div className='col-md-4 text-center'>
              <img alt='' className='profile_img mb-2' src = {f2} />
              <h4>Mr. Ankit Jain</h4>
              <p>Co-Founder</p>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
  
export default OurTeam;
