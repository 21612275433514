import React from 'react';
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum'
import {postCareerData, subscribeUser} from '../../../utils/api'
import {isEmail, validatePhoneNumber} from '../../../utils/helper'
import { useState } from 'react';
import toast from "react-hot-toast";

function Careers() {
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [subscribeEnable, setSubscribeEnable] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [resume, setResume] = useState('');

  function onChangeHandler(e){
    setSubscribeEmail(e.target.value)
    if(isEmail(subscribeEmail)){
      setSubscribeEnable(true)
    }else{
      setSubscribeEnable(false)
    }
  }
  async function subscribeHandler(){
    try{
      const payload = {
        'email': subscribeEmail,
        "email_group": "Job Application"
      }
      const res = await subscribeUser(payload);
      toast.success(res, {position: 'top-center'})
      setSubscribeEmail('');
      setSubscribeEnable(false)
    }catch(err){
      console.log(err)
    }
  }
  function handleFile(e) { 
    let file = e.target.files[0]; 
    setResume(file); 
  } 
  async function jobApplyHandler(){
    try{
      let formData = new FormData();
      formData.append("file", resume); 
      formData.append("name", name); 
      formData.append("email", email); 
      formData.append("mobile", mobile); 
      formData.append("address", address); 
      formData.append("state", state); 
      formData.append("city", city); 
      const resp = await postCareerData(formData)
      console.log(resp)
      toast.success(resp.message.message, {position: 'top-center'})
      setName("")
      setEmail("")
      setAddress("")
      setState("")
      setCity("")
      setMobile("")
      setResume("")
    }catch(err){
      console.log(err)
    }
  }

  function isValidated(){
    let isdisabled = false;
    if(name == '' || name.length < 3){
      isdisabled = true
    }else if(email == '' || email.length < 3 || !isEmail(email)){
      isdisabled = true
    }else if(mobile == '' || mobile.length < 3 || !validatePhoneNumber(mobile)){
      isdisabled = true
    }else if(address == '' || address.length < 3){
      isdisabled = true
    }else if(city == '' || city.length < 3){
      isdisabled = true
    }else if(state == '' || state.length < 3){
      isdisabled = true
    }else if(resume == '' || resume.length < 3){
      isdisabled = true
    }
    return isdisabled
  }

  return (
    <div className='container'>
      <div className="mx-2 cnt_about_us">
        <BredCrum><Link to={`/`} >Home</Link> / Careers</BredCrum>
        <div className='common-heading mt-3 mb-0'>
          <h1>Careers</h1>
        </div>
        <div className='row mt-4'>
          <div className='col-md-6 col-sm-12 mb-4'>
            <h5>Subscribe for Job Openings</h5>
            <p className=' fw-bold'>Email:</p>
            <input onChange={(e) => onChangeHandler(e)}  className='form-control  mb-3' placeholder='Enter Your Email Address' />
            <button  disabled={!subscribeEnable} onClick={(e) => subscribeHandler()} className='btn btn-outline-primary'>Subscribe</button>
            <p className='mt-3'>
              <b>Contact HR</b>
            </p>
            <p>Name : Pooja Arora</p>
            <p>Email : pooja.arora@aksum.co.in</p>
          </div>
          <div className='col-md-6 col-sm-12 mb-4'>
            <h5>Job Application Form</h5>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">Name</label>
              <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">Email</label>
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" />
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">Mobile Number</label>
              <input value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" className="form-control" />
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">Current Address</label>
              <textarea onChange={(e) => setAddress(e.target.value)}type="text" className="form-control" defaultValue={address}></textarea>
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">State</label>
              <input value={state} onChange={(e) => setState(e.target.value)} type="text" className="form-control" />
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">City</label>
              <input value={city} onChange={(e) => setCity(e.target.value)} type="text" className="form-control" />
            </div>
            <div className='form-group mb-3'>
              <label className="form-label fw-bold">Resume</label>
              <input onChange={(e) => handleFile(e)} type="file" accept = "application/msword, application/pdf, image/*" className="form-control" />
            </div>
            <div className='form-group mb-3 text-center'>
              <button disabled={isValidated()} onClick={(e) => jobApplyHandler()} className='btn btn-primary'>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default Careers;
