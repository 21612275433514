import React, { useEffect, useState } from "react";
import { verifyOTP } from "../utils/api";
import toast from "react-hot-toast";


function Otp({ onSuccess, onBack, onResendOTP, payload }) {
  const [count, setCount] = useState(120);
  const [otp, setOtp] = useState("");
  const [verifying] = useState(false);

  const handleVerifyOtp = async () => {
    try{
        const resp = await verifyOTP({...payload, otp: otp})
        console.log(resp);
        onSuccess({...payload, ...resp})
    }catch(err){
        console.log(err)
        toast.error(err.response.data.message.message, {position: 'top-center'})
    }
  };
  function handleSendOtp(){
    onResendOTP()
    setCount(120)
    setOtp('')
  }

  useEffect(() => {
    const cd = setTimeout(() => {
      if (count > 0) setCount(count - 1);
    }, 1000);
    
    return () => {
      clearTimeout(cd);
    };
  }, [count]);

  return (
    <>
    <div className="container">
        <div className="row justify-content-center mt-5 mb-5">
            <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="login-box">
                <div className='common-heading mt-2 mb-2'>
        <h1>
                        Verify OTP
                    </h1>
                    </div>
                    
                    <div className="row mt-3 mb-3">
                        <div className="col-12 text-center mb-2">
                            <div> OTP Sent to email {payload.email}.{" "}</div>
                        </div>
                        <div className="col-12">
                            <button onClick={onBack} className="btn btn-outline-primary w-100">
                                Change Email
                            </button>
                        </div>
                    </div>
                    <input
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        maxLength={6}
                        placeholder="Enter OTP"
                        className="form-control"
                    />
                    <div className="text-lg mt-3 mb-2 text-center">
                        {count !== 0 && <span>00:{count > 9 ? count : "0" + count}</span>}
                    </div>
                    <div className="mt-3">
                            <button
                                disabled={verifying}
                                className="btn btn-primary mb-2 w-100"
                                onClick={handleVerifyOtp}>
                                {verifying ? "Please wait..." : "Verify"}
                            </button>
                        <div className="text-center">
                            {count === 0 && 
                                <>
                                    <p>OTP session expired, resend OTP.</p>
                                    <button className="btn btn-outline-primary w-100" onClick={handleSendOtp}>Resend Otp</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Otp;
