import React, { useState } from 'react';
import {getSubCategories, getProducts} from '../../utils/api'
import toast from "react-hot-toast";
import useCart from '../../utils/hooks/useCart'
import useUser from '../../utils/hooks/useUser'
import { useNavigate } from 'react-router-dom';

function RequirementV({cats}) {
    const navigate = useNavigate();
    const { user } = useUser();
    const [subCats, setSubCats] = useState([]);
    const [products, setProducts] = useState([]);

    const [selCat, setSelCat] = useState("-1");
    const [selSubCat, setSelSubCat] = useState("-1");
    const [product, setProduct] = useState("-1");
    const [product_code, setProductCode] = useState("-1");
    const [productName, setProductName] = useState("");
    const [qty, setQty] = useState("0");
    const { cart, setCart } = useCart();

    function categoryChangeHandler(e){
        setSelCat(e.target.value)
        loadSubcats(e.target.value)
    }
    function subCategoryChangeHandler(e){
        setSelSubCat(e.target.value)
        if(e.target.value === "-2"){
          setProducts([])
        }else{
          loadProducts(e.target.value)
        }
    }

    async function loadSubcats(category_name){
        try {
            const payload = {
                "category": category_name
            }
            const resp = await getSubCategories(payload);
            const {categories} = resp;
            setSubCats(categories);
        }catch(err){
            console.log(err)
        }
    }

    async function loadProducts(category_name){
        try {
            const payload = {
                "category": category_name,
                "start": 0,
                "length": 20
            }
            const resp = await getProducts(payload);
            const {products} = resp;
            setProducts([...products]);
        }catch(err){
            console.log(err)
        }
    }

    function PlaceEnquiryHandler(){
      if((user !== null) && (user.type === "supplier" || user.type === "Supplier")){
        toast.error("Supplier can not add item in cart!", {position: 'top-center'})
      }else{
        let payload = {
          "item_code": (product_code === "-1" || product_code === "-2") ?  '' : product_code,
          "item_name": (product === "-2" || product === "-1") ? productName : product,
          "quantity": qty,
          "category": (selCat === "-1" || selCat === "-2") ? "Other" : selCat
        }

        const tempCart = cart === null ? [] : cart;
        tempCart.push(payload)
        setCart([...tempCart])

        navigate(`/checkout`);
      }
    }

    function getValidated(){
      let isDisabled = true;
      if(selCat === "-2" && productName.length >= 2 && parseInt(qty) > 0){
        isDisabled = false
      }
      else if(selSubCat === "-2" && productName.length >= 2 && parseInt(qty) > 0){
        isDisabled = false
      }
      else if(product === "-2" && productName.length >= 2 && parseInt(qty) > 0){
        isDisabled = false
      }else if(selCat !== "-2" && selSubCat !== "-2" && product !== "-2" && parseInt(qty) > 0){
        isDisabled = false
      }

      return isDisabled
    }


    return (
      <section className="tell-us-your-req telld mt-3">
      <div className=' reqs'>
          <h6>Tell Us your Requirements</h6>
        <div className='inner_cnt'>
          <div className='row'>
            <div className="form-group col-12 mb-4">
              <select className='form-select w-100' value={selCat} onChange={(e) => categoryChangeHandler(e)}>
                <option disabled value={'-1'} >Select Category</option>
                {
                    cats.map((cat, i) => {
                        return(
                            <option key={`${cat.slug}_${i}`} value={cat.slug}>{cat.name}</option>
                        )
                    })
                }
                <option value={'-2'}>Other</option>
              </select>
            </div>
            <div className="form-group col-12 mb-4">
              <select className='form-select' value={selSubCat} onChange={(e) => subCategoryChangeHandler(e)}>
                <option disabled value="-1">Select Sub Category</option>
                {
                    subCats.map((cat, i) => {
                        return(
                            <option key={`${cat.name}_${i}`} value={cat.name}>{cat.name}</option>
                        )
                    })
                }
                <option value={'-2'}>Other</option>
              </select>
            </div>
            <div className="form-group col-12 mb-4">
              <select className='form-select' value={product} onChange={(e) => {
                let vv = e.target.value.split("_")
                setProduct(vv[0])
                setProductCode(vv[1])
              }}>
                <option disabled value={'-1'}>Select Product</option>
                {
                    products.map((item, i) => {
                        return(
                            <option key={`${item.item_name}_${i}`} value={`${item.item_name}_${item.item_code}`}>{item.item_name}</option>
                        )
                    })
                }
                <option value={'-2_-2'}>Other</option>
              </select>
            </div>
            {selCat === "-2" || selSubCat === "-2" || product_code === "-2"  ?
              <div className="form-group col-12 mb-4">
                <input placeholder='Enter your own product' type='text' className='form-control' onChange={(e) => setProductName(e.target.value)} value={productName}  />
              </div>
            : <></> }
            <div className="form-group col-12 mb-4">
              <input type='number' className='form-control' onChange={(e) => setQty(e.target.value)} value={qty}  />
            </div>
            <div className="form-group col-12">
              <button disabled={getValidated()} onClick={(e) => PlaceEnquiryHandler()} className='btn btn-primary w-100  mt-0'>Place an Enquiry</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default RequirementV
