import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import Products from './Products';
import BredCrum from '../../../../components/BredCrum';
import BrandCard from './BrandCard'
import { getBrands, getNameBySlug } from '../../../../utils/api';

function Brand() {
    // eslint-disable-next-line
    const rowLen = 20;
    // eslint-disable-next-line
    const [hasMore, setHasMore] = useState(false);
    // eslint-disable-next-line
    const [startIndex, setStartIndex] = useState(0);
    const {brandname = ''} = useParams();
    const [brands, setBrands] = useState([]);
    const [brand_name, setBrandName] = useState('');
    async function loadBrands(){
        try{
            const resp = await getBrands();
            setBrands(resp)
        }catch(err){
            console.log(err)
        }
    }
    async function getBrandNameBySlug(){
        try{
            const resp = await getNameBySlug(brandname, 'Brand');
            setBrandName(resp)
        }catch(err){
            console.log(err)
        }
    }
    async function loadMoreBrands(){

    }
    useEffect(() => {
        if(brandname.length === 0){
            setBrands([])
        }else{
            loadBrands()
            getBrandNameBySlug()
        }
    }, [brandname])
    return (
        <>
            <div className="container-fluid">
                <BredCrum>
                    <Link to={`/`} >Home</Link> / {brand_name}
                </BredCrum>
                {  brandname.length !== 0 ?
                <>
                    <h3 className='mt-2'>{brandname}</h3>
                    <Products brandname={brandname} />
                </>
                :
                    <div className='my-3'>
                        <div className="row cnt_products">
                            {
                                brands.map((item, index) => {
                                    return <BrandCard key={`${item.brand}_${index}`} brand={item} />        
                                })
                            }
                        </div>

                        {
                            hasMore ? <>
                                <div className='row mb-3 mt-4'>
                                    <div className='col-md-12 text-center'>
                                        <button onClick={() => loadMoreBrands()} className='btn btn-outline-primary'>Load more</button>
                                    </div>
                                </div>
                            </>:<></>
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default Brand;
