import axios from "axios";
import { getToken, removeUserAndToken } from "./tokenHelper";

export const baseURL = `${window.baseurl}api/method/`; //"http://68.178.168.99:8000/api/method/";

export const api = axios.create({
  // baseURL: "https://aksum.co.in/api/",
  baseURL: baseURL
});

const token = getToken();
export const config = {
  headers: {
    Authorization: token,
    Accept: "application/json", 
    "Content-Type": "application/json",
  }
}

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = "/login";
      removeUserAndToken();
    }
    return Promise.reject(error);
  }
);

// type LOGIN_PAYLOAD = {
//   email: string;
//   password: string;
// };
// type LOGIN_RESPONSE = {
//   success: {
//     message: string;
//     data: {
//       id: string;
//       email: string;
//       userType: string;
//       access_token: string;
//       refresh_token: string;
//       expires_in: string;
//       orgName: string;
//       phone: string;
//     };
//   };
// };

//aksum.overrides.user.web_login
export function login(payload){
  return new Promise((resolve, reject) => {
    api.post("aksum.overrides.user.web_login", payload)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getUIResource(secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_web_ui_resource", {
      /*params: {'type': 'Home Banner Image'}*/
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getProfile(secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_profile", 
    {
      headers: {
        Authorization: secret_token
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCategories(){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_all_web_categories", config)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getBrands(){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_brands", config)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getBusinessLines(){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_business_lines", config)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getSearchProducts(payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.search_products", {
        params: payload
      })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function subscribeUser(payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.subscribe", {
        params: payload
      })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function getSubCategories(payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_web_sub_categories", 
    {
      params: payload
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function getProducts(payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_products", 
    {
      params: payload
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getNameBySlug(slugname, doctype=''){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_slug_name", 
    {
      params: {'slug': slugname, 'doctype': doctype}
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function getProductList(payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_web_products", 
    {
      params: payload
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//aksum.overrides.user.sign_up
//aksum.overrides.user.web_signup
export function registerUser(payload){
  return new Promise((resolve, reject) => {
    api.post("aksum.overrides.user.web_signup", payload)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function verifyOTP(payload){
  return new Promise((resolve, reject) => {
    api.post("aksum.overrides.user.web_signup", payload)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function resetPassword(payload){
  return new Promise((resolve, reject) => {
    api.post("aksum.overrides.user.reset_password", payload)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}



export function get_profile(secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_profile", 
    {
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function update_profile(secret_token, payload){
  let apiurl = `aksum.api.update_profile`
  return new Promise((resolve, reject) => {
    api.post(`${apiurl}`, 
    payload,
    {
      headers: {
        Authorization: secret_token
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function updatePassword(secret_token, payload){
  return new Promise((resolve, reject) => {
    api.post("aksum.overrides.user.change_password", 
    payload,
    {
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getPurchaseInvoices(secret_token, payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_sales_invoices", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// export function getPurchaseOrders(payload){
//   return new Promise((resolve, reject) => {
//     api.get("aksum.api.get_purchase_orders", 
//     {
//       params: {payload},
//       headers: {
//         Authorization: token,
//         Accept: "application/json", 
//         "Content-Type": "application/json",
//       }
//     })
//       .then(function ({data}) {
//         resolve(data.message.message);
//       })
//       .catch(function (error) {
//         reject(error);
//       });
//   });
// }

export function getQuotations(secret_token, payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_quotations", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getOpportunities(secret_token, payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_opportunities", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getRequestForQuotation(payload, secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_rfqs", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getSalesInvoices(payload, secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_purchase_invoices", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getSaleOrders(payload, secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_purchase_orders", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getPurchaseOrders(secret_token, payload){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_sales_orders", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getSupplierQuotations(payload, secret_token){
  return new Promise((resolve, reject) => {
    api.get("aksum.api.get_supplier_quotations", 
    {
      params: {payload},
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function placeOrder(payload, secret_token){
  let apiurl = `aksum.api.send_enquiry`
  return new Promise((resolve, reject) => {
    api.post(`${apiurl}`, 
    payload,
    {
      headers: {
        Authorization: secret_token,
        Accept: "application/json", 
        "Content-Type": "application/json",
      }
    })
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function sendContact(payload){
  let apiurl = `aksum.api.contact_us`
  return new Promise((resolve, reject) => {
    api.post(`${apiurl}`, payload)
      .then(function ({data}) {
        resolve(data.message.message);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function downloadPDF_old(secret_token, payload){
  let apiurl = `aksum.api.download_pdf`
  return new Promise((resolve, reject) => {
    api.post(`${apiurl}`, 
    payload,
    {
      responseType: 'arraybuffer',
      headers: {
        Authorization: secret_token,
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    })
      .then(function ({data}) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function downloadPDF(secret_token, name, docType){
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}aksum.api.download_pdf`,
      { "doctype": docType, "name": name},
      {
          responseType: 'arraybuffer',
          headers: {
              Authorization: secret_token,
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
          }
      })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.pdf`); 
          document.body.appendChild(link);
          link.click();
          resolve("done");
      })
      .catch(function (error) {
        reject(error);
      });
    });
}

export function uploadDoc(secret_token, payload){
  let apiurl = `aksum.api.upload_file`
  return new Promise((resolve, reject) => {
    axios({
      url: `${baseURL}${apiurl}`, 
      method: "POST", 
      headers: { 
        authorization: secret_token, 
        "Content-Type": "multipart/form-data"
      }, 
      data: payload, 
    }) 
      .then(function ({data}) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function postCareerData(payload){
  let apiurl = `aksum.api.submit_job_application`
  return new Promise((resolve, reject) => {
    axios({
      url: `${baseURL}${apiurl}`, 
      method: "POST", 
      headers: {
        "Content-Type": "multipart/form-data"
      }, 
      data: payload, 
    }) 
      .then(function ({data}) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

