import React, { useEffect, useState } from 'react';
import ProductCard from '../../../components/ProductCard';
import { Link, useParams } from 'react-router-dom';
import {getBrands, getCategories, getSearchProducts, getProductList, getBusinessLines} from '../../../utils/api';
import BredCrum from '../../../components/BredCrum';

function Search() {
    const rowLen = 20;
    const [hasMore, setHasMore] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [loading , setLoading] = useState(true);
    const [selectedCat , setSelectedCat] = useState("-1");
    const [selectedBrand , setSelectedBrand] = useState("-1");
    const [selectedBusiness , setSelectedBusiness] = useState("-1");

    const {keywords = ''} = useParams();
    
    const [productlist, setProducts] = useState([]);
    const [cats, setCats] = useState([]);
    const [brands, setBrands] = useState([]);
    const [business, setBusiness] = useState([]);

    async function loadCategories(){
        try{
            const resp = await getCategories();
            console.log(resp)
            setCats(resp.categories);
        }catch(err){
            console.log(err)
        }
    }
    async function loadBrands(){
        try{
            const resp = await getBrands();
            console.log(resp)
            setBrands(resp);
        }catch(err){
            console.log(err)
        }
    }
    async function loadBusiness(){
        try{
            const resp = await getBusinessLines();
            console.log(resp)
            setBusiness(resp);
        }catch(err){
            console.log(err)
        }
    }

    async function loadData(){
        await loadBrands()
        await loadCategories()
        await loadBusiness()
        // if(keywords.length){
        //     await loadProductsBySearch()
        // }
    }

    // async function loadProductsBySearch(){
    //     await setStartIndex(0)
    //     try{
    //         let kw = keywords;
    //         kw = kw.split("-").join(" ");
    //         const resp = await getSearchProducts({"txt": kw});
    //         console.log(resp)
    //         setProducts(resp);
    //         await setStartIndex(resp.length)
    //         if(resp.length < rowLen){
    //             setHasMore(false)
    //         }else{
    //             setHasMore(true)
    //         }
    //         setLoading(false);
    //     }catch(err){
    //         console.log(err)
    //         setLoading(false);
    //     }
    // }

    async function loadProductsByFilter({brand='', cat='', business_item=''}){
        setLoading(true)
        
        await setStartIndex(0)
        try{
            const payload = {
                "txt": keywords,
                "category": cat.length === 0 ? selectedCat.replace("-1", "") : cat.trim().replace("-1", ""),
                "brand": brand.length === 0 ? selectedBrand.replace("-1", "") : brand.trim().replace("-1", ""),
                "business_line": business_item.length === 0 ? selectedBusiness.replace("-1", "") : business_item.trim().replace("-1", ""),
                "start": parseInt(0),
                "length": parseInt(rowLen)
            }
            const resp = await getProductList(payload);
            setProducts(resp)
            setStartIndex(resp.length)
            if(resp.length < rowLen){
                setHasMore(false)
            }else{
                setHasMore(true)
            }
            setLoading(false);
        }catch(err){
            console.log(err)
            setLoading(false);
        }
    }


    async function loadmoreHandler(){
        setLoading(true)
        console.log(startIndex)
        try{
            const payload = {
                "txt": keywords,
                "category": selectedCat,
                "brand": selectedBrand,
                "business_line": selectedBusiness,
                "start": parseInt(startIndex),
                "length": parseInt(rowLen)
            }
            const resp = await getProductList(payload);
            setProducts((prevItems) => [
                ...prevItems,
                ...resp,
            ]);
            setStartIndex(startIndex+resp.length)
            if(resp.length < rowLen){
                setHasMore(false)
            }else{
                setHasMore(true)
            }
            setLoading(false);
        }catch(err){
            console.log(err)
            setLoading(false);
        }
    }
    async function resetClickHandler(){
        setSelectedBrand("-1")
        setSelectedCat("-1")
        setSelectedBusiness("-1")
    }
    async function searchClickHandler(){
        loadProductsByFilter({brand:selectedBrand, cat:selectedCat, business_item:selectedBusiness})
    }
    useEffect(() => {
        loadData()
        loadProductsByFilter({brand:'', cat:'', business_item:''})
    }, [keywords, selectedBrand])
    return (
        <div className="container cnt_media">
            <BredCrum>
                <Link to={`/`} >Home</Link> / Search
            </BredCrum>
            <div className='common-heading mt-3 mb-2'>
        <h1>Search</h1>
        </div>
            {/* <div className='row'>
                <div className='col-md-3 pt-1'>
                    <h5>Filters</h5>
                    </div>
                <div className='col-md-3 mb-2'>
                    <select 
                        onChange={(e) => {
                            setSelectedBrand(e.target.value)
                        }}
                        className='form-select' value={selectedBrand} >
                        <option key={1} value={"-1"}>select Brand</option>
                        {
                            brands.map((item) => {
                                return(
                                    <option key={item.brand} value={item.brand}>{item.brand}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='col-md-3 mb-2'>
                    <select 
                        onChange={(e) => {
                            setSelectedCat(e.target.value)
                        }}
                        className='form-select'  value={selectedCat}>
                        <option value={"-1"} >select Category</option>
                        {
                            cats.map((item) => {
                                return(
                                    <option key={item.name} value={item.name}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='col-md-3 mb-2'>
                    <select 
                        onChange={(e) => {
                            setSelectedBusiness(e.target.value)
                        }}
                        className='form-select'  value={selectedBusiness}>
                        <option value={"-1"} >select Businessline</option>
                        {
                            business.map((item) => {
                                return(
                                    <option key={item.business_line} value={item.business_line}>{item.business_line}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-md-end text-sm-center'>
                    <button onClick={(e) => resetClickHandler()} className='btn btn-outline-primary mx-sm-0 mx-md-2  my-2 btn-sm-block'>Reset filter</button>
                    <button onClick={(e) => searchClickHandler()} className='btn btn-primary  my-2 btn-sm-block'>Search</button>
                </div>
            </div>
            <div className='row'>
                <hr className='mt-3' />
            </div> */}
            <div className="row cnt_products">
                {
                    loading === false && productlist.length === 0 ? <><p>{keywords.length ? 'No result found!' : ''}</p></> : <></>
                }
                {
                    productlist.map((product, index) => {
                        return <ProductCard key={index} product={product} />        
                    })
                }
            </div>
            {
                hasMore ? <>
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-12 text-center'>
                            <button onClick={() => loadmoreHandler()} className='btn btn-outline-primary'>Load more</button>
                        </div>
                    </div>
                </>:<></>
            }
            
        </div>
    );
  }
  
export default Search;
