import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import useUser from "../../utils/hooks/useUser";
import { login, getProfile} from "../../utils/api";
import { saveToken } from "../../utils/tokenHelper";

function Login() {
    const navigate = useNavigate();
    const [loginBy, setLoginBy] = useState(1);
    const [loginStep, setLoginStep] = useState(1);
    const { setUser } = useUser();
    const [error, setError] = useState(null);
    const [verifyMsg, setVerifyMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tmpid, settmpid] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [count, setCount] = useState(null);

    const { register, handleSubmit, reset } = useForm();


    async function saveData(sid, type){
      saveToken(`${sid}`)
      const resp = await getProfile(`${sid}`);
      const {address, cin, company_name, email, gst, name, pan, phone, turnover} = resp;

      setUser({
        address: address, 
        cin: cin, 
        company_name: company_name, 
        email: email, 
        gst: gst, 
        name: name, 
        pan: pan, 
        phone: phone, 
        turnover:turnover,
        type: type
      });
      navigate("/user/profile", { replace: true });
    }
    const onSubmitOTP = async (data) => {
      console.log(data)
      setError(null);
      const { otp } = data;

      setIsLoading(true);
      try{
        const payload = {
          "tmp_id": tmpid,
          "otp": otp,
          "email": email,
          "phone": phone
        }
        const res = await login(payload);
        const { sid, type} = res;
        setIsLoading(false);
        toast.success("Logged In successfully!", {position: 'top-center'});
        saveData(sid, type)
      }catch(err){
        setIsLoading(false);
        setError(err.response.data.message.message);
      }
    }

    const onSubmit = async (data) => {
      setError(null);
      const { uemail, upassword, phone } = data;

      setEmail(uemail)
      let payload = {}
      if(loginBy === 1){
        payload = {
          email: uemail,
          pwd: upassword
        }
      } else if(loginBy === 2){
        payload = {
          email: uemail
        }
      } else if(loginBy === 3){
        setPhone(phone)
        payload = {
          phone: phone
        }
      }

      setIsLoading(true);
      try {
        const res = await login(payload);
        setIsLoading(false);

        const { sid, type, tmp_id} = res;

        if(tmp_id != null){
          setVerifyMsg("Please verify your email")
          setLoginStep(2)
          settmpid(tmp_id)
          reset()
          setCount(120)
        }else{
          toast.success("Logged In successfully!", {position: 'top-center'});
          saveData(sid, type)
        }

      }catch(err){
        setIsLoading(false);
        const {tmp_id = null} = err.response.data.message.message;
        if(tmp_id == null){
          setError(err.response.data.message.message);
        }else{
          //console.log(tmp_id);
          settmpid(tmp_id)
          setLoginStep(2)
          setCount(120)
        }

      }

      // const { uemail, upassword } = data;
      // setIsLoading(true);
      // try{
      //   const res = await login(uemail, upassword);
      //   setIsLoading(false);
      //   // const {api_key, api_secret, type} = res;
      //   const { sid, type} = res;
      //   // saveToken(`token ${api_key}:${api_secret}`)
      //   saveToken(`${sid}`)
      //   // const resp = await getProfile(`token ${api_key}:${api_secret}`);
      //   const resp = await getProfile(`${sid}`);
      //   const {address, cin, company_name, email, gst, name, pan, phone, turnover} = resp;
        
      //   setUser({
      //     address: address, 
      //     cin: cin, 
      //     company_name: company_name, 
      //     email: email, 
      //     gst: gst, 
      //     name: name, 
      //     pan: pan, 
      //     phone: phone, 
      //     turnover:turnover,
      //     type: type
      //   });
      // }catch(err){
      //   setIsLoading(false);
      //   console.log(err);
      //   setError(err.response.data.message.message);
      // }
    };


    // async function getOTP(type){
    //   // const payload = {
    //   //   type: 
    //   // }
    //   // const res =  await getOTP();
    //   // console.log();
    // }

    async function resendClickHandler(){
      try {
        const payload = {
          email: email,
          phone: phone
        }
        const res = await login(payload);
        setIsLoading(false);

        const { sid, type, tmp_id} = res;

        if(tmp_id != null){
          setLoginStep(2)
          settmpid(tmp_id)
          reset()
          setCount(120)
        }else{
          toast.success("Logged In successfully!", {position: 'top-center'});
          saveData(sid, type)
        }
      }catch(err){
        setIsLoading(false);
        const {tmp_id = null} = err.response.data.message.message;
        if(tmp_id == null){
          setError(err.response.data.message.message);
        }else{
          settmpid(tmp_id)
          setLoginStep(2)
          setCount(120)
        }
      }
    }

    useEffect(() => {
      const cd = setTimeout(() => {
        if (count > 0) setCount(count - 1);
      }, 1000);
  
      return () => {
        clearTimeout(cd);
      };
    }, [count]);

    return (
      <div className="container ">
        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="login-box">
            <div className='common-heading mt-2 mb-2'>
        <h1>Log In</h1>
        </div>
              <div className="row">
                <div className="col">
                  {error && (
                    <p className="text-sm text-danger mb-4">
                      {error}
                    </p>
                  )}
                </div>
              </div>
              {
                loginStep === 1 ?
                  <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
                  {
                    loginBy === 1 ?
                      <div className="row">
                        <div className="form-group">
                          <input
                            type="text"
                            required
                            {...register("uemail")}
                            placeholder="Email address"
                            className="form-control mb-3"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            {...register("upassword")}
                            required
                            type="password"
                            placeholder="Password"
                            className="form-control mb-3"
                          />
                        </div>
                        <div className="form-group mb-3">
                          <Link className="mb-4" to="/forgot-password">Forgot Password?</Link>
                        </div>
                        {/* <div className="form-group">
                          <div className="min-h-[78px] mt-4 mb-4">
                            <ReCAPTCHA
                              size="normal"
                              sitekey="6LcYHugiAAAAALt9HDcid_sslL4YjHYOPGYxucDs"
                              onChange={() => setVerified(true)}
                            />
                          </div>
                        </div> */}
                        <div className="form-group mb-3">
                          <button className="btn btn-primary w-100">
                            {isLoading ? "Please Wait..." : "Login"}
                          </button>
                        </div>
                        <hr/>
                        <div className="form-group">
                          <button onClick={() => {
                            reset()
                            setLoginBy(2)
                          }} className="btn btn-outline-primary w-100">Login with OTP</button>
                        </div>
                        <div className="form-group">
                          <div className="text-center font-medium mt-4">
                            {"Don't have an account? "}
                            <Link to="/sign-up" className="text-blue-400">
                              Sign Up
                            </Link>
                          </div>
                        </div>
                      </div>
                    : <></>
                  }
                  {
                    loginBy === 2 ?
                      <div className="row">
                        <div className="form-group">
                          <h4 className="mb-3">Login with OTP</h4>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required
                            {...register("uemail")}
                            placeholder="Email address"
                            className="form-control mb-3"
                          />
                        </div>
                        <div className="form-group">
                          <button className="btn btn-primary w-100">
                            {isLoading ? "Please Wait..." : "Get OTP"}
                          </button>
                        </div>
                        <div className="form-group">
                          <button onClick={() => {
                            reset()
                            setLoginBy(3)
                          }} className="btn btn-outline-primary mt-3 w-100">Use mobile Number to get OTP</button>
                        </div>
                        <div className="form-group">
                          <button onClick={() => {
                            reset()
                            setLoginBy(1)
                          }} className="btn btn-outline-primary mt-3 w-100">Login with Email, password</button>
                        </div>
                      </div>
                    : <></>
                  }
                  {
                    loginBy === 3 ?
                        <div className="row">
                          <div className="form-group">
                            <h4 className="mb-3">Login with OTP</h4>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              required
                              {...register("phone")}
                              placeholder="Phone Number"
                              className="form-control mb-3"
                            />
                          </div>
                          <div className="form-group mb-2">
                            <button className="btn btn-outline-secondary w-100">
                              {isLoading ? "Please Wait..." : "Get OTP"}
                            </button>
                          </div>
                          <hr/>
                          <div className="form-group mb-2">
                            <button onClick={() => {
                              reset()
                              setLoginBy(2)
                              }} className="btn btn-outline-primary w-100">Use email address to get OTP</button>
                          </div>
                          <div className="form-group">
                            <button onClick={() => {
                              reset()
                              setLoginBy(1)
                              }} className="btn btn-outline-primary w-100">Login with Email, password</button>
                          </div>
                        </div>
                    : <></>
                  }
                </form>
                :
                <form onSubmit={handleSubmit(onSubmitOTP)}  className="flex flex-col gap-5">
                  <div className="row">
                    {
                      verifyMsg.length !== 0 ?
                      <div className="form-group">
                        <p>{verifyMsg}</p>
                      </div>
                      :
                      <></>
                    }
                    <div className="form-group">
                      <input 
                        type="hidden"
                        required
                        {...register("gotp")}
                      />
                      <input
                        type="text"
                        required
                        {...register("otp")}
                        placeholder="OTP"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary w-100 mb-2" type="submit">
                        {"Submit"}
                      </button>
                    </div>
                    <div className="form-group text-center">
                        {count !== 0 && <span>00:{count > 9 ? count : "0" + count}</span>}
                    </div>
                    {
                      count <=0 ? 
                      <>
                        <div className="form-group text-center">
                          <p>OTP session expired, resend OTP.</p>
                        </div>
                        <div className="form-group">
                          <button onClick={(e) => {
                            e.preventDefault()
                            resendClickHandler()
                            return ;
                          }} className="btn btn-outline-primary w-100">
                            {"Resend OTP"}
                          </button>
                        </div>
                      </>
                      :<></>
                    }
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Login;
  