import React, { useState } from "react";
import { updatePassword } from "../../../utils/api";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { isPasswordStrong } from "../../../utils/helper";
import {getToken} from '../../../utils/tokenHelper'
import useUser from "../../../utils/hooks/useUser";

function ChangePassword() {
  const { logout } = useUser();
  const { register, handleSubmit, watch } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState();

  const onSubmit = async (data) => {
    setError("");
    if (!isStrong) {
      toast.error("Password is not strong enough!", {position: 'top-center'});
      return;
    }
    setisLoading(true)
    try{
      const token = getToken();
      const resp = await updatePassword(token, data)
      console.log(resp);
      setisLoading(false)
      logout()
    }catch(err){
      setisLoading(false)
      console.log(err.response.data.message.message)
      setError(err.response.data.message.message)
      toast.error(err.response.data.message.message, {position: 'top-center'});
    }
  };

  const pw = watch("new_password");

  const isStrong = isPasswordStrong(pw);

  return (
    <div className="container ">
      <div className="row justify-content-center mt-3">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="login-box">
          <div className='common-heading mt-2 mb-2'>
        <h1>
              Update Password
            </h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={isLoading} className="flex flex-col gap-5">
                <input
                  {...register("old_password")}
                  type="password"
                  required
                  placeholder="Current Password"
                  className="form-control mb-3"
                />
                <input
                  {...register("new_password")}
                  required
                  type="password"
                  placeholder="New Password"
                  className="form-control mb-3"
                />
                <input
                  {...register("confirm_password")}
                  type="password"
                  required
                  placeholder="Confirm Password"
                  className="form-control mb-3"
                />
                {!isStrong && (
                  <div>
                    <p className="font-medium">Password guidelines:</p>
                    <ul className="mt-1 list-inside list-disc text-sm">
                      <li>At least 6 characters</li>
                      <li>A mixture of both uppercase and lowercase letters</li>
                      <li>A mixture of letters and numbers</li>
                    </ul>
                  </div>
                )}
                {error && (
                  <p className="text-sm text-danger mb-4">
                    {error}
                  </p>
                )}
                <button type="submit" disabled={!isStrong} className="btn btn-outline-primary w-100">
                  Update
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
