import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


function ForgotPassword() {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [step, setStep] = useState(1);
    const [fpwdType, setFpwdType] = useState(1);
    const { register, handleSubmit } = useForm();
    const [tmpid, settmpid] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(null);

    const onSubmit = async (data) => {
      setError(null);
      const { uemail, umobile } = data;
      console.log(uemail, umobile)

      const payload = {
        email: uemail,
        phone: umobile
      }
      setEmail(uemail)
      setMobile(umobile)
      setIsLoading(true);
      try {
        const res = await resetPassword(payload);
        setIsLoading(false);

        const { tmp_id} = res;
        console.log(res)
        if(tmp_id != null){
          settmpid(tmp_id)
          setStep(2)
          setCount(20)
        }

      }catch(err){
        setIsLoading(false);
        const {tmp_id = null} = err.response.data.message.message;
        if(tmp_id == null){
          setError(err.response.data.message.message);
        }else{
          console.log(tmp_id);
          settmpid(tmp_id)
          setStep(2)
          setCount(20)
        }
      }
    }
    const onSubmitOTP = async (data) => {
      setError(null);
      const { otp, pwd, cpwd } = data;

      if(pwd !== cpwd){
        setError('Password, confirm password mismatch');
      }else{
        setIsLoading(true);
        try{
          const payload = {
            "tmp_id": tmpid,
            "otp": otp,
            "email": email,
            "phone": mobile,
            "pwd": pwd
          }
          const res = await resetPassword(payload);
          console.log(res);
          setIsLoading(false);
          toast.success("Password Updated Successfully!", {position: 'top-center'})
          navigate("/login", { replace: true })
        }catch(err){
          setIsLoading(false);
          console.log(err)
          setError(err.response.data.message.message);
        }
      }
    }

    async function resendClickHandler(){
      setIsLoading(true);
      try {
        const payload = {
          email: email, 
          phone: mobile
        }
        const res = await resetPassword(payload);
        setIsLoading(false);

        const { tmp_id} = res;
        console.log(res)
        if(tmp_id != null){
          settmpid(tmp_id)
          setStep(2)
          setCount(20)
        }

      }catch(err){
        setIsLoading(false);
        const {tmp_id = null} = err.response.data.message.message;
        if(tmp_id == null){
          setError(err.response.data.message.message);
        }else{
          console.log(tmp_id);
          settmpid(tmp_id)
          setStep(2)
          setCount(20)
        }
      }
    }

    useEffect(() => {
      const cd = setTimeout(() => {
        if (count > 0) setCount(count - 1);
      }, 1000);
  
      return () => {
        clearTimeout(cd);
      };
    }, [count]);

    return (
      <div className="container ">
        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="login-box"><div className='common-heading mt-2 mb-2'>
        <h1>Forget Password</h1>
        </div>
              <div className="row">
                <div className="col">
                  {error && (
                    <p className="text-sm text-danger mb-4">
                      {error}
                    </p>
                  )}
                </div>
              </div>
              {
                step === 1 ?
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
                  {
                    fpwdType === 1 ?
                    <div className="row">
                      <div className="form-group">
                        <input
                          type="text"
                          required
                          {...register("uemail")}
                          placeholder="Email address"
                          className="form-control mb-3"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <button className="btn btn-primary w-100">
                          {"Get OTP"}
                        </button>
                      </div>
                      <div className="form-group">
                        <button onClick={() => setFpwdType(2)} className="btn btn-outline-primary w-100">
                          {"Login with Mobile Number"}
                        </button>
                      </div>
                    </div>
                    :
                    <div className="row">
                      <div className="form-group">
                        <input
                          type="text"
                          required
                          {...register("umobile")}
                          placeholder="Mobile number"
                          className="form-control mb-3"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <button className="btn btn-primary w-100">
                          {"Get OTP"}
                        </button>
                      </div>
                      <div className="form-group">
                        <button onClick={() => setFpwdType(1)} className="btn btn-outline-primary w-100">
                          {"Login with Email Address"}
                        </button>
                      </div>
                    </div>
                  }
                </form>
                :
                <></>
              }
              {
                step === 2 ?
                <form onSubmit={handleSubmit(onSubmitOTP)} className="flex flex-col gap-5">
                  <div className="row">
                    <div className="form-group">
                      <input
                        type="text"
                        required
                        {...register("otp")}
                        placeholder="Enter OTP"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required
                        {...register("pwd")}
                        placeholder="Enter Password"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required
                        {...register("cpwd")}
                        placeholder="Enter Confirm Password"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary w-100 mb-2">
                        {"Submit"}
                      </button>
                    </div>

                    <div className="form-group text-center">
                        {count !== 0 && <span>00:{count > 9 ? count : "0" + count}</span>}
                    </div>
                    {
                      count <=0 ? 
                      <>
                        <div className="form-group text-center">
                          <p>OTP session expired, resend OTP.</p>
                        </div>
                        <div className="form-group">
                          <button onClick={(e) => resendClickHandler()} className="btn btn-outline-primary w-100">
                            {"Resend OTP"}
                          </button>
                        </div>
                      </>
                      :<></>
                    }
                  </div>
                </form>
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    );
}

export default ForgotPassword;
