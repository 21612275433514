import { useContext, useEffect } from "react";
import { CartContext } from "./CartContext";
import { removeToken, removeUserAndToken } from "../tokenHelper";
import { useNavigate } from "react-router-dom";

const useCart = () => {
  const context = useContext(CartContext);
  const navigate = useNavigate();

  if (!context) {
    throw new Error("useCart hook must be called inside CartContext");
  }

  const { cart, setCart, clearCart } = context;

  useEffect(() => {
    if (cart) localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return { cart, setCart, clearCart };
};

export default useCart;
