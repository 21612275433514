import {useEffect, useState} from 'react';
import {getProducts} from '../../../../utils/api';
import ProductCard from '../../../../components/ProductCard'

function Products({catname}) {
    const [productlist, setProducts] = useState([]);
    const rowLen = 20;
    const [hasMore, setHasMore] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    async function loadProducts(category_name){
        const payload = {
            "category": category_name,
            "start": parseInt(0),
            "length": parseInt(rowLen)
        }
        const resp = await getProducts(payload);
        const {products} = resp;
        setProducts(products);
        setStartIndex(startIndex+products.length)
        if(products.length < rowLen){
            setHasMore(false)
        }else{
            setHasMore(true)
        }
    }
    async function loadMoreHandler(){
        const payload = {
            "category": catname,
            "start": parseInt(startIndex),
            "length": parseInt(rowLen)
        }
        const resp = await getProducts(payload);
        const {products} = resp;
        setProducts((prevItems) => [
            ...prevItems,
            ...products,
        ]);
        setStartIndex(startIndex+products.length)
        if(products.length < rowLen){
            setHasMore(false)
        }else{
            setHasMore(true)
        }
    }
    
    useEffect(() => {
        loadProducts(catname)
    }, [catname])
    return (
        <>
            <div className="row cnt_products mt-2">
                {
                    productlist.map((product, index) => {
                        return <ProductCard key={index} product={product} />        
                    })
                }
                {
                    productlist.length == 0 ? 
                    <p>Currently there are 0 product in this category</p> 
                    : <></>
                }
            </div>
            {
                hasMore ? <>
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-12 text-center'>
                            <button onClick={() => loadMoreHandler()} className='btn btn-outline-primary'>Load more</button>
                        </div>
                    </div>
                </>:<></>
            }
        </>
    );
}

export default Products;
