import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import useUser from "../../../utils/hooks/useUser";

function UserIndex() {
  const { user } = useUser();
  const { pathname } = useLocation();

  const isActive = (path) => {
    return pathname === `/user/${path}`;
  };

  const getClassNames = (href) => {
    return clsx(
      isActive(href) && "active",
      ""
    );
  };

  if (!user) return null;
  return (
    <>
      <div className="container py-10 md:py-20">
        <div className="gap-10 rounded-2xl bg-primary-700 p-8 md:p-16 lg:flex">
            <div className="mb-2 mt-3 utablinks">
              {user.type === "buyer" || user.type === "Customer" ? (
                <>
                  <Link
                    className={getClassNames("buyer/opportunity")}
                    to="buyer/opportunity">
                    Opportunity
                  </Link>
                  <Link
                    className={getClassNames("buyer/quotation")}
                    to="buyer/quotation">
                    Quotation
                  </Link>
                  <Link
                    className={getClassNames("buyer/orders")}
                    to="buyer/orders">
                    Purchase Order
                  </Link>
                  <Link
                    className={getClassNames("buyer/invoice")}
                    to="buyer/invoice">
                    Purchase Invoice
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    className={getClassNames("supplier/request-quotation")}
                    to="supplier/request-quotation">
                    Request For Quotation
                  </Link>
                  <Link
                    className={getClassNames("supplier/quotation")}
                    to="supplier/quotation">
                    Supplier Quotation
                  </Link>
                  <Link
                    className={getClassNames("supplier/sales-orders")}
                    to="supplier/sales-orders">
                    Purchase Order
                  </Link>
                  <Link
                    className={getClassNames("supplier/sales-invoice")}
                    to="supplier/sales-invoice">
                    Purchase Invoice
                  </Link>
                </>
              )}
              {/* <hr className="border-primary-400" /> */}
              <Link className={getClassNames("profile")} to="profile">
                Profile
              </Link>
              <Link
                className={getClassNames("change-password")}
                to="change-password">
                Change Password
              </Link>
            </div>
          <div className="mt-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserIndex;
