import React from 'react';
import { Link } from 'react-router-dom';
import BredCrum from '../../../components/BredCrum'

function FAQ() {
    const data = [
      {heading: 'Accordion Item 1', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
      {heading: 'Accordion Item 2', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
      {heading: 'Accordion Item 3', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
      {heading: 'Accordion Item 4', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
      {heading: 'Accordion Item 5', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
      {heading: 'Accordion Item 6', body: `Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.`},
    ]
    return (
      <div className='container'>
        <div className="mx-2 cnt_about_us">
          <BredCrum><Link to={`/`} >Home</Link> / FAQ's</BredCrum>
          <div className='common-heading mt-3 mb-0'>
            <h1>FAQ's</h1>
          </div>
          <h5 className="heading my-4">Help and Customer Service</h5>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <p><strong>Frequently Asked Questions</strong></p>
              <div className="accordion accordion-flush mb-4" id="accordionFlushExample">
                {
                  data.map((item , i) => {
                    return(
                      <div className="accordion-item" key={i}>
                        <h2 className="accordion-header" id={`heading_${i}`}>
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${i}`} aria-expanded="false" aria-controls={`collapse_${i}`}>
                            {item.heading}
                          </button>
                        </h2>
                        <div id={`collapse_${i}`} className="accordion-collapse collapse" aria-labelledby={`heading_${i}`} data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">{item.body}</div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='col-md-6 col-sm-12 text-end'>
              <p>still need help? <Link to={'/contact-us'} className='mx-2 btn btn-primary'>Contact us</Link></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
export default FAQ;
